import React, { useState } from "react";

import axios from "axios";

import { useFormik } from "formik";
import { IP } from "../api/ip";

import { useMutation } from "react-query";

import { Label, Input, Spinner } from "reactstrap";
import { forgotPasswordSchema } from "../validation-schema";

import NavbarComponent from "../components/navbar.component";
import Footer from "../components/footer.component";

import { toast } from "react-toastify";
import { handleScrollToTop } from "../utils/helperFunction";

const ForgotPasswordPage = () => {
  document.title = "نسيت كلمة المرور | صناع السعادة";

  const [emailSent, setEmailSent] = useState(false);

  const URL = `${IP}/api`;

  const sendEmail = (payload) => {
    return axios.post(`${URL}/accounts/password_reset/`, payload);
  };

  const sendEmailMutation = useMutation(sendEmail, {
    onSuccess: (response) => {
      setEmailSent(true);
    },
    onError: (data) => {
      toast.error("هناك خطأ ما");
    },
  });

  const onSubmit = () => {
    sendEmailMutation.mutate(values);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: forgotPasswordSchema,
      onSubmit,
      enableReinitialize: true,
    });

  return (
    <>
      <div className="auth-wrapper forgot-password-wrapper">
        <NavbarComponent />

        <div className="form-container">
          <div className="form">
            {emailSent ? (
              <h2 className="text-purple mb-2">
                تفقد بريدك الالكتروني من فضلك
              </h2>
            ) : (
              <>
                <h2 className="text-purple mb-2">هل نسيت كلمة السر؟</h2>
                <p className="text-purple mt-5">
                  أدخل بريدك الإلكتروني وسنرسل لك رابط إعادة التعيين.
                </p>
                <form className="text-end" dir="rtl" onSubmit={handleSubmit}>
                  <Label>البريد الإلكتروني</Label>
                  <Input
                    dir="rtl"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    className={
                      errors.email && touched.email
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="emailInput"
                    placeholder="أدخل البريد الإلكتروني"
                    name="email"
                  />
                  {errors.email && touched.email && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.email}
                    </div>
                  )}

                  <div className="text-center">
                    <button
                      type="submit"
                      className="main-btn"
                      disabled={sendEmailMutation.isLoading}
                    >
                      {sendEmailMutation.isLoading ? "يعالج" : "إرسال"}
                      {sendEmailMutation.isLoading && (
                        <Spinner size="sm" className="flex-shrink-0 ms-2" />
                      )}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer scroll={handleScrollToTop} />
    </>
  );
};

export default ForgotPasswordPage;
