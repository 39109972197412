import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const NoteModal = ({ toggleModal, modal, data }) => {
  const DangerousHTML = ({ htmlContent }) => {
    return (
      <div
        className="text-end"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>إشعارات</ModalHeader>
      <ModalBody>
        <div className="py-4 text-wrap">
          <h3 className="text-end">
            <b>{data?.title}</b>
          </h3>
        </div>
        <DangerousHTML htmlContent={data?.description} />
      </ModalBody>
    </Modal>
  );
};

export default NoteModal;
