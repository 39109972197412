import React, { useRef, useEffect, useState } from "react";
import NavbarComponent from "../components/navbar.component";
import Footer from "../components/footer.component";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


import { Navigation } from "swiper/modules";

import { Container } from "reactstrap";
import CourseCard from "../components/home/courseCard.component";


import { useQuery } from "react-query";

import axios from "axios";
import { IP } from "../api/ip";

import { Link, useNavigate } from "react-router-dom";
import ScheduleTable from "../components/scheduleTable.component";

import CourseHeading from "../assets/images/new-identity/courses-icon.png";
import ScheduleHeading from "../assets/images/new-identity/schedule-icon.png";
import BannerWeb from "../assets/images/banner-web.png";
import banner from '../assets/images/new-identity/banner-05.png'
import { handleScrollToTop } from "../utils/helperFunction";
import { useStore } from "../api/useStore";

import EnrollmentSuccessModal from "../components/enrollment-success.modal";

import { slideInDown, fadeIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';


const HomePage = () => {
  const userStatus = useStore((state) => state?.userStatus);

  const logOut = () => {
    useStore.setState({
      userStatus: false,
      accessToken: false,
      refreshToken: false,
    });
  };
  document.title = "الهيئة العامة للترفيه - الدورات التدريبية القصيرة";
  const [searchData, setSearchData] = useState(null);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  const [searchLoading, setSearchLoading] = useState(null);
  const [screenSize, setScreenSize] = useState(1);
  const navigate = useNavigate();

  const URL = `${IP}/api`;

  const {
    isLoading: loadingCurrent,
    isError: isCurrentError,
    data: currentSchedule,
    isSuccess: currentSuccess,
    refetch: currentRefetch,
  } = useQuery("schedules", async () => axios.get(`${URL}/courses/schedules/`));

  const courses = currentSchedule?.data?.schedules ?? [];

  const {
    isLoading: loadingPrevious,
    isError: isPreviousError,
    data: previousSchedule,
    isSuccess: previousSuccess,
    refetch: previousRefetch,
  } = useQuery("previous-schedules", async () =>
    axios.get(`${URL}/courses/expired-schedules/`)
  );

  const previousCourses = previousSchedule?.data?.schedules ?? [];

  const coursesListRef = useRef(null);

  useEffect(() => {
    let hashFragment = window.location.hash;

    if (hashFragment === "#courses" && coursesListRef.current) {
      const scrollPosition = 0.8 * window.innerHeight;

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [window.location.hash]);

  const handleScrollToCoursesOnHome = () => {
    const scrollPosition = 0.7 * window.innerHeight;

    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  };

  const handleSearchResults = (results) => {
    setSearchData(results);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width >= 1093) {
        setScreenSize(3);
      } else if (width >= 730) {
        setScreenSize(2);
      } else if (width > 568) {
        setScreenSize(1);
      }
    };

    // Initial check on mount
    handleResize();

    // Attach event listener for resize events
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);


  
  const styles = {
    slideDown: {
      animationDuration: '1s',
      animationName: Radium.keyframes(slideInDown, 'slideInDown'),
    },
    fade: {
      animationDuration: '.5s',
      animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      'height': '100vh',
      'backgroundColor': '#ffffff60',
    },
  };
  const [showModal, setShowModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  
  const openModal = () => {
    setScrollPosition(window.scrollY); // Save current scroll position
    setShowModal(true);
  };

  // Function to close the modal and reset the scroll position
  const closeModal = () => {
    setShowModal(false);
  };
  
  useEffect(() => {
    
    if (!showModal){
      window.scrollTo({top: scrollPosition, left:0, behavior: 'instant'}); 
    }
    
  }, [showModal])


  return (
    <>
    {
        showModal ? 
        <div style={styles}>
          <StyleRoot>
            <EnrollmentSuccessModal
            back={closeModal}/> 
            </StyleRoot>
          </div>
        :

   
    <div className="home-bg">
      <div className="home-wrapper">
        <NavbarComponent
          activePage={"home"}
          handleScrollToCoursesOnHome={handleScrollToCoursesOnHome}
        />
        {/* <div className="home-wrapper-big-green-bar"></div> */}
        <div className="home-signin-btn">
          {userStatus ? (
            <button
              className=""
              onClick={() => {
                navigate("/profile");
              }}
            >
              الحساب التعريفي
            </button>
          ) : (
            <button
              className=""
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              تسجيل الدخول
            </button>
          )}
        </div>
        <div className="home-header d-flex flex-column mt-5 mt-lg-0 flex-lg-row-reverse align-items-lg-center justify-content-center justify-content-lg-between">
          <div>
            <div className="d-flex justify-content-end mb-3">
              <div className="home-header-top-green-bar"></div>
            </div>
            <h1 className="text-end me-3 me-lg-5 pe-lg-4">
              خذ حياتك المهنية <br />
              إلى المستوى التالي
            </h1>
            <div className="d-flex align-items-center justify-content-end me-3 me-lg-5 pe-lg-4">
              {userStatus ? (
                <button onClick={logOut} className="home-header-btn text-start">
                  تسجيل الخروج
                </button>
              ) : (
                <button
                  onClick={() => navigate("/sign-up")}
                  className="home-header-btn text-start"
                >
                  اشـترك
                </button>
              )}
            </div>
          </div>
          <div className="home-header-main-img mt-5 pt-md-5 pt-0 mt-lg-0">
            <img src={banner} />
          </div>
        </div>
      </div>

      <div className="courses-list mb-5">
        <Container className="home-container">
          <div className=" d-flex flex-row-reverse align-items-center gap-3 mb-5">
            <div className="course-heading-img">
              <img src={CourseHeading} />
            </div>
            <h2 className="text-black mb-0">الدورات</h2>
          </div>
          <div className="">
            {loadingCurrent || searchLoading ? (
              <div className="course-placeholders d-flex flex-row-reverse gap-4 flex-wrap justify-content-center justify-content-md-between">
                {[1, 2, 3].map((_, idx) => (
                  <div
                    className="card"
                    key={idx}
                    style={{
                      width: "300px",
                      height: "400px",
                      opacity: "0.3",
                    }}
                    aria-hidden="true"
                  >
                    <div
                      className="bg-secondary"
                      style={{
                        width: "300px",
                        height: "280px",
                      }}
                    ></div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-6"></span>
                      </h5>
                      <p className="card-text placeholder-glow">
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-6"></span>
                        <span className="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : !courses.length > 0 && !loadingCurrent ? (
              <div className="col-12 text-secondary mt-3">
                <h5 className="text-center">لا يوجد دورة لإظهارها</h5>
              </div>
            ) :
            searchData ? (
              <div className="course-cards d-flex flex-wrap align-items-center gap-4 justify-content-center">
                {searchData?.map((course) => (
                  <div className="" key={course?.id}>
                    <CourseCard course={course} />
                  </div>
                ))}
              </div>
            ) : (
              <Swiper
                dir="rtl"
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                slidesPerView={screenSize}
                spaceBetween={30}
                grabCursor={true}
              >
                {courses?.map((course) => (
                  <SwiperSlide key={course?.id}>
                    <div className="">
                      <CourseCard course={course} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </Container>

        <Container className="home-container">
          <div
            className=" d-flex flex-row-reverse align-items-end gap-4 py-5"
            style={{ marginTop: "100px" }}
          >
            <div className="course-heading-img schedule-heading-img">
              <img src={ScheduleHeading} />
            </div>
            <h2 className="text-black ">الجدول</h2>
          </div>
          <div className="container schedule-table-wrapper mt-5">
            <ScheduleTable 
              showResponse={() => openModal()}
            />
          </div>
        </Container>

        <Container className="mt-5 home-container">
          <div className=" d-flex flex-row-reverse align-items-center gap-3 my-5 pt-5">
            <div className="course-heading-img">
              <img src={CourseHeading} />
            </div>
            <h2 className="text-black mb-0">الدورات السابقة</h2>
          </div>
          <div className="">
            {loadingPrevious ? (
              <div className="course-placeholders d-flex flex-row-reverse gap-4 flex-wrap justify-content-center justify-content-md-between">
                {[1, 2, 3].map((_, idx) => (
                  <div
                    className="card"
                    key={idx}
                    style={{
                      width: "300px",
                      height: "400px",
                      opacity: "0.3",
                    }}
                    aria-hidden="true"
                  >
                    <div
                      className="bg-secondary"
                      style={{
                        width: "300px",
                        height: "280px",
                      }}
                    ></div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-6"></span>
                      </h5>
                      <p className="card-text placeholder-glow">
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-6"></span>
                        <span className="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : !previousCourses.length > 0 && !loadingPrevious ? (
              <div className="col-12 text-secondary mt-3">
                <h5 className="text-center">لا يوجد دورة لإظهارها</h5>
              </div>
            ) : (
              <Swiper
                dir="rtl"
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                slidesPerView={screenSize}
                spaceBetween={30}
                grabCursor={true}
              >
                {previousCourses?.map((course) => (
                  <SwiperSlide key={course?.id}>
                    <div className="">
                      <CourseCard course={course} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </Container>
       
      </div>

      <Footer scroll={handleScrollToTop} />
    </div>
  }
  </>
  );
};

export default HomePage;





{
  /* <div className="courses-list mt-4">
        <div className="container-fluid bg-green">
        <div className="container home-container">

          <div className=" d-flex flex-row-reverse">
            <h2 className="text-white mb-3 mt-5">جميع الدورات</h2>
          </div>

          <div className="row pb-5">
              <CourseListing/>
          </div>
        </div>
        </div>
      </div> */
}







/* Dummy slider sample of pagination */

  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  // const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  // const [sliderData, setSliderData] = useState([]);

  // const {
  //   // isLoading: loadingCurrent,
  //   // isError: isCurrentError,
  //   data: dummyData,
  //   isSuccess: dummySuccess,
  //   refetch: pagiRefetch,
  // } = useQuery("dummyPagination", async () =>
  //   axios.get(
  //     `https://jsonplaceholder.typicode.com/posts?_page=${currentPage}&_limit=10`
  //   )
  // );

  // const handleSlideChange = async (swiper) => {
  //   if (
  //     swiper.isEnd &&
  //     // currentPage <
  //     // totalPages &&
  //     !isLoadingNextPage
  //   ) {
  //     // Fetch next page data
  //     setIsLoadingNextPage(true);
  //     try {
  //       const nextPage = currentPage + 1;
  //       const nextPageData = await axios.get(
  //         `https://jsonplaceholder.typicode.com/posts?_page=${nextPage}&_limit=10`
  //       );

  //       // Append new data to existing data
  //       const newData = nextPageData.data;
  //       setSliderData((prev) => [...prev, ...newData]);
  //       // pagiRefetch([...courses, ...newData]);

  //       // Update current page
  //       setCurrentPage(nextPage);
  //     } catch (error) {
  //       console.error("Error fetching next page:", error);
  //     } finally {
  //       setIsLoadingNextPage(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (dummyData?.data?.length > 0) {
  //     setSliderData(dummyData?.data);
  //   }
  // }, [dummyData]);