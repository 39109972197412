import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import axios from "axios";
import { useStore } from "./useStore";
import { IP } from "./ip";
import { useNavigate } from "react-router-dom";


const getToken = () => {
  try {
    return useStore.getState();
  } catch {
    return null;
  }
};

const baseURL = `${IP}/api`;

const useAxios = () => {
  const navigate = useNavigate()
  const accessToken = getToken()["accessToken"];
  const refreshToken = getToken()["refreshToken"];

  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    
    if (!accessToken){
      useStore.setState({
          userStatus: false,
          accessToken: null,
          refreshToken: null,
      })

      navigate('/sign-in')
  }

    const user = jwtDecode(accessToken);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    const response = await axios.post(`${baseURL}/accounts/token/refresh`, {
      refresh: refreshToken,
    });

    useStore.setState({ accessToken: response.data["access"] });

    req.headers.Authorization = `Bearer ${response.data["access"]}`;
    return req;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401 && error?.response?.data?.message !== 'كلمة سر قديمة ليست صحيحة') {
        const response = await axios
          .post(`${baseURL}/accounts/token/refresh`, {
            refresh: refreshToken,
          })
          .catch((err) => {
            useStore.setState({
              userStatus: false,
              userType: null,
              accessToken: null,
              refreshToken: null,
              name: null,
              emai: null,
          })
          navigate('/sign-in')
          });

        useStore.setState({ accessToken: response.data["access"] });

        error.config.headers.Authorization = `Bearer ${response.data["access"]}`;
        return axiosInstance(error.config);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxios;
