import React, { useEffect, useState } from "react";
import Select from "react-select";

import axios from "axios";
import { useQuery } from "react-query";

import { IP } from "../../api/ip";

import { useNavigate, Navigate } from "react-router-dom";

const SearchCourses = ({ onSearchResults, searchLoading }) => {
  const URL = `${IP}/api`;

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedYear, setSelectedMonth] = useState(null);

  const {
    isLoading: isSearchLoading,
    isError: isSearchError,
    data: searchResults,
    isSuccess: isSearchSuccess,
    refetch: refetchSearch,
  } = useQuery(
    "search",
    async () =>
      axios.get(
        `${URL}/courses/schedules/?location=${
          selectedLocation && selectedLocation?.value
            ? selectedLocation.value
            : ""
        }&course=${
          selectedCourse && selectedCourse?.value ? selectedCourse.value : ""
        }&month=${
          selectedYear && selectedYear?.value ? selectedYear.value : ""
        }`
      ),
    {
      enabled: false,
      onSuccess: (res) => {
        onSearchResults(res?.data?.schedules);
      },
    }
  );

  const handleSearchResults = async () => {
    try {
      searchLoading(true);
      await refetchSearch();
    } catch (error) {}
    searchLoading(false);
  };

  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data: yearsResponse,
    isSuccess,
    refetch,
  } = useQuery("years", async () => axios.get(`${URL}/courses/get-years`), {
    cacheTime: 0,
  });

  let years = yearsResponse?.data ?? [{}];

  const {
    isLoading: loadingCourses,
    isError: isLoadingError,
    data: coursesResponse,
    isSuccess: coursesSuccess,
    refetch: coursesRefetch,
  } = useQuery("courses", async () => axios.get(`${URL}/courses/get-courses`), {
    cacheTime: 0,
  });

  let courses = coursesResponse?.data ?? [{}];

  const {
    isLoading: loadingLocations,
    isError: isLocationsError,
    data: locationsResponse,
    isSuccess: locationsSuccess,
    refetch: locationsRefetch,
  } = useQuery(
    "locations",
    async () => axios.get(`${URL}/courses/locations-dropdown`),
    { cacheTime: 0 }
  );

  let locations = locationsResponse?.data ?? [{}];

  if (years.length > 0) {
    years = [{ label: "اختر الشهر", value: "" }].concat(years);
  }

  if (courses?.length > 0) {
    courses = [{ label: "اختر الدورة", value: "" }].concat(courses);
  }

  if (locations.length > 0) {
    locations = [{ label: "اختر موقعا", value: "" }].concat(locations);
  }

  useEffect(() => {}, []);

  return (
    <div className="search-container d-flex flex-md-row-reverse gap-4 flex-wrap align-items-center justify-content-between">
      <h3 style={{ flex: "3" }}>البحث عن الدورة</h3>
      <div className="search-boxes d-flex flex-column flex-md-row-reverse gap-3 justify-content-around justify-content-md-between w-100">
        <div className="search-select d-flex justify-content-center">
          <Select
            className="basic-single home-select"
            classNamePrefix="select"
            isRtl={true}
            isSearchable={true}
            name="color"
            value={selectedCourse}
            options={courses}
            placeholder={"اختر الدورة"}
            onChange={(selectedOption) => setSelectedCourse(selectedOption)}
          />
        </div>

        <div className="search-select d-flex justify-content-center">
          <Select
            className="basic-single home-select"
            classNamePrefix="select"
            isRtl={true}
            isSearchable={true}
            name="color"
            value={selectedLocation}
            options={locations}
            placeholder={"اختر الموقع"}
            onChange={(selectedOption) => setSelectedLocation(selectedOption)}
          />
        </div>

        <div className="search-select d-flex justify-content-center">
          <Select
            className="basic-single home-select"
            classNamePrefix="select"
            isRtl={true}
            isSearchable={true}
            name="color"
            value={selectedYear}
            options={years}
            placeholder={"اختر الشهر"}
            onChange={(selectedOption) => setSelectedMonth(selectedOption)}
          />
        </div>
      </div>

      <div style={{ flex: "2" }} className="justify-content-end">
        <button
          onClick={() => {
            handleSearchResults();
          }}
          className="home-search-btn"
        >
          البحث
        </button>
      </div>
    </div>
  );
};

export default SearchCourses;
