import React from "react";
import Radium, { StyleRoot } from 'radium';
import { bounceInDown } from 'react-animations';
import responseIcon1 from '../assets/images/response-icon-1.png'
import responseIcon2 from '../assets/images/response-icon-2.png'

const EnrollmentSuccessModal = ({ back }) => {


    const styles = {
        fadeIn: {
            animation: 'x 1s',
            animationName: Radium.keyframes(bounceInDown, 'bounceInDown')
            }
        };

    return (
        <StyleRoot>
          <div style={[styles.fadeIn]}>
            <div className="response-overlay">
              
              <div className="response-content">
                <img className="response-icon-1" src={responseIcon1} alt="" />
                    <h4>تم إرسال طلب التسجيل <br/>في الدورة بنجاح</h4>

                <img className="response-icon-2" src={responseIcon2} alt="" />
                    <h5>عند قبولكم في الدورة سيتم</h5>
                    <h5>إرسال بريد إلكتروني بالقبول 
                    </h5>
                    <button onClick={back}>
                    الرجوع إلى الصفحة الرئيسية
                    </button>
              </div>

            </div>
          </div>
        </StyleRoot>
      );
}

export default Radium(EnrollmentSuccessModal);