import React, { useState, useEffect } from "react";

import { PDFDocument, ReadingDirection, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { toast } from "react-toastify";
import createCertificate from "../../utils/certificate-generator";
import vendorCreateCertificate from "../../utils/vendor-certificate-generator";

import ListBtn from "../../assets/images/profilePage/list-btn.jpeg";
import { formatFullDate } from "../../utils/formatter";

const ProfileTableRow = ({ registration, getQuestionnaire, getScheduleID }) => {
  const [modifiedPdfBytes, setModifiedPdfBytes] = useState(null);

  const loadPdf = async () => {
    try {

      var modifiedBytes;
      if (registration.trainer == "ESM" || registration.trainer == "Event Academy" || registration.trainer == "Fhm" || registration.trainer == "Fennec"){
        modifiedBytes = await vendorCreateCertificate(registration);
        }
        else{
        modifiedBytes = await createCertificate(registration);

      }


      if (modifiedBytes) {
        setModifiedPdfBytes(modifiedBytes);
      }
    } catch (error) {
      console.error("Error loading PDF:", error);
    }
  };

  const downloadPdf = () => {
    if (modifiedPdfBytes) {
      toast.info("انتظر من فضلك");
      const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Certificate.pdf";
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    if (registration?.course_name) {
      loadPdf();
    }
  }, [registration]);

  return (
    <tr>
      <td className="first-row">
        {registration?.feedback?.submitted ? (
          "مُقَدَّم"
        ) : registration?.feedback?.is_active &&
          !registration?.feedback?.submitted ? (
          <button
            className="table-register-btn"
            onClick={() => getScheduleID(registration?.schedule)}
          >
            تعليق
          </button>
        ) : (
          "-"
        )}
      </td>
      <td className="first-row">
        {registration?.show_certificate ? (
          <p className="mb-0 download-certificate" onClick={downloadPdf}>
            تحميل الشهادة
          </p>
        ) : (
          "-"
        )}
      </td>

      <td className="first-row">
        <div className="d-flex align-items-center justify-content-center">
          {registration?.post_assessment?.is_active &&
          !registration?.post_assessment?.submitted ? (
            <img
              className="profil-list-btn"
              src={ListBtn}
              alt="btn"
              onClick={() =>
                getQuestionnaire({
                  questionnaireID: registration?.post_assessment?.assessment,
                  registrationID: registration?.post_assessment?.id,
                })
              }
            />
          ) : registration?.post_assessment?.submitted ? (
            <p className={"mb-0"}>مُقَدَّم</p>
          ) : (
            <>التقييم غير متاح</>
          )}
        </div>
      </td>
      <td className="first-row">
        <div className="d-flex align-items-center justify-content-center">
          {registration?.pre_assessment?.is_active &&
          !registration?.pre_assessment?.submitted ? (
            <img
              className="profil-list-btn"
              src={ListBtn}
              alt="btn"
              onClick={() =>
                getQuestionnaire({
                  questionnaireID: registration?.pre_assessment?.assessment,
                  registrationID: registration?.pre_assessment?.id,
                })
              }
            />
          ) : registration?.pre_assessment?.submitted ? (
            <p className={"mb-0"}>مُقَدَّم</p>
          ) : (
            <>التقييم غير متاح</>
          )}
        </div>
      </td>
      <td className="first-row" dir="rtl">
        {registration?.start_date
          ? formatFullDate(registration.start_date)
          : "N/A"}
        <br />
        {registration?.end_date ? formatFullDate(registration.end_date) : "N/A"}
      </td>
      <td className="first-row">{registration?.course_name ?? "N/A"}</td>
    </tr>
  );
};

export default ProfileTableRow;
