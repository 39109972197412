import React from "react";

import "../assets/sass/contact.scss";

import NavbarComponent from "../components/navbar.component";

import { Col, Container, Input, Label, Row, Spinner } from "reactstrap";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";
import { FaInstagram, FaXTwitter, FaYoutube } from "react-icons/fa6";
import { TiSocialGooglePlus } from "react-icons/ti";
import Footer from "../components/footer.component";
import { contactUsSchema } from "../validation-schema";

import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useMutation } from "react-query";

import { IP } from "../api/ip";
import { handleScrollToTop } from "../utils/helperFunction";

const ContactPage = () => {
  document.title = "اتصل بنا | صناع السعادة";

  const onSubmit = () => {
    sendMessageMutation.mutate(values);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: contactUsSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const sendMessage = (payload) => {
    const URL = `${IP}/api`;

    return axios.post(`${URL}/contact-us`, payload);
  };

  const sendMessageMutation = useMutation(sendMessage, {
    onSuccess: () => {
      toast.success("وقد وردت الرسالة");
      resetForm();
    },
    onError: (data) => {
      if (data?.response?.status === 400 && !!data?.response?.data?.message) {
        toast.error(data.response.data.message);
      } else if (
        data?.response?.status === 500 &&
        !!data?.response?.data?.message
      ) {
        toast.error(data.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  return (
    <>
      <div className="contact-wrapper">
        <NavbarComponent activePage={"contact"} />
      </div>
      <Container>
        <div className="py-5">
          <h2 className="text-end text-orange">اتصل بنا</h2>
          <div className="form-wrapper bg-white">
            <form className="text-end" dir="rtl" onSubmit={handleSubmit}>
              <Row>
                <Col sm="6">
                  <Label className="contact-form-label">الاسم</Label>
                  <Input
                    dir="rtl"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="name"
                    className={
                      errors.name && touched.name
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="nameInput"
                    placeholder="أدخل الاسم"
                    name="name"
                  />
                  {errors.name && touched.name && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.name}
                    </div>
                  )}
                </Col>

                <Col sm="6">
                  <Label className="contact-form-label">
                    البريد الإلكتروني
                  </Label>
                  <Input
                    dir="rtl"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    className={
                      errors.email && touched.email
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="emailInput"
                    placeholder="أدخل البريد الإلكتروني"
                    name="email"
                  />
                  {errors.email && touched.email && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.email}
                    </div>
                  )}
                </Col>

                <Col>
                  <Label className="contact-form-label">الرسالة</Label>
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="اكتب رسالتك"
                    type="textarea"
                    rows="5"
                    name="message"
                    className={
                      errors.message && touched.message
                        ? "form-control form-control-icon is-invalid"
                        : "form-control form-control-icon border-green"
                    }
                    value={values.message}
                  />
                  {errors.message && touched.message && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.message}
                    </div>
                  )}
                </Col>
              </Row>
              <div className="text-end mt-4">
                <button
                  type="submit"
                  className="contact-form-btn"
                  disabled={sendMessageMutation.isLoading}
                >
                  {sendMessageMutation.isLoading ? "يعالج" : "إرسال"}
                  {sendMessageMutation.isLoading && (
                    <Spinner size="sm" className="flex-shrink-0 ms-2" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* <div className="contact-map-section pt-3 pb-5">
          <div className="d-flex align-items-center justify-content-end">
            <h2 className="text-purple pb-1 mb-3">تجدنا</h2>
          </div>
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.2116228862105!2d46.728698!3d24.788206100000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efdc759cd02a5%3A0x56743938ab78ab8e!2sTakamol%20Holding!5e0!3m2!1sen!2s!4v1701320494458!5m2!1sen!2s"
              width="100%"
              height="450"
              // style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div> */}

        {/* <div className="contact-info-section ">
          <div className="d-flex align-items-center justify-content-end">
            <h2 className="text-purple pb-1 mb-3">معلومات الاتصال</h2>
          </div>
          <div className="contact-info mb-5">
            <div className="info-box px-3 px-sm-5">
              <div className="d-flex justify-content-end">
                <h5>هاتف</h5>
              </div>
              <div className="d-flex-justify-content-start mt-3">
                <div className="d-flex gap-3 align-items-center">
                  <FaPhoneAlt className="info-icon" />
                  <p className="mb-0">+966 11 111 1111</p>
                </div>
              </div>
            </div>
            <hr />
           
            <div className="info-box px-3 px-sm-5">
              <div className=" d-flex justify-content-end">
                <h5>عنوان</h5>
              </div>
              <div className="d-flex-justify-content-start">
                <div className="d-flex gap-3 align-items-center">
                  <FaMapMarkerAlt className="info-icon" />
                  <p className="mb-0 info-address">
                    B4، مجمع غرناطة للأعمال، الشهداء، الرياض، 13241 المملكة
                    العربية السعودية
                  </p>
                </div>
              </div>
            </div>
            <hr />
           
            <div className="info-box px-3 px-sm-5">
              <div className=" d-flex justify-content-end">
                <h5>بريد إلكتروني</h5>
              </div>
              <div className="d-flex-justify-content-start">
                <div className="d-flex gap-3 align-items-center mt-3">
                  <TfiEmail className="info-icon" />
                  <p className="mb-0">info@geatakamol.com</p>
                </div>
              </div>
            </div>
            <hr />
            <div className="info-box px-3 px-sm-5">
              <div className=" d-flex justify-content-end">
                <h5>تابعنا</h5>
              </div>
              <div className="d-flex-justify-content-start mt-2">
                <div className="d-flex gap-2 align-items-center">
                  <a
                    href="https://www.linkedin.com/company/general-entertainment-authority/"
                    target="_blank"
                  >
                    <div className="info-social">
                      <FaLinkedinIn className="info-social-icon" />
                    </div>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCHJpU7eYVx5SUg48L31mfaQ"
                    target="_blank"
                  >
                    <div className="info-social">
                      <FaYoutube className="info-social-icon" />
                    </div>
                  </a>
                  <a href="https://twitter.com/gea_sa" target="_blank">
                    <div className="info-social">
                      <FaXTwitter className="info-social-icon" />
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/gea_saudi/"
                    target="_blank"
                  >
                    <div className="info-social">
                      <FaInstagram className="info-social-icon" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Container>
      <Footer scroll={handleScrollToTop} />
    </>
  );
};

export default ContactPage;
