let IP;

if (process.env.NODE_ENV === 'production'){
    IP = 'https://api.geatakamol.com'
} else {
    IP = 'http://127.0.0.1:8000'
}


export {IP};
