import React from "react";

import {Button, Modal, ModalBody, Spinner} from "reactstrap";

import useAxios from "../../api/useAxios";

import { toast } from 'react-toastify'

import { useMutation } from 'react-query';

import { useStore } from "../../api/useStore";


const ConfirmDeleteModal = ({ modal, toggle  }) => {

  const api = useAxios()

  const deleteCourse = () => {
    return api.delete(`/accounts/profile`)
}

const deleteMutation = useMutation(deleteCourse, {
    onSuccess: () => {
        toast.success('تم الحذف')

        useStore.setState({
          userStatus: false,
          accessToken: false,
          refreshToken: false,
        });
    },

    onError: (response) => {


        if (response?.response?.status == 404 && response?.response?.data?.message){
            toast.error(response.response.data.message)
        }
        else{
            toast.error('هناك خطأ ما')
        }

    }
})


  return (
    <Modal isOpen={modal} toggle={toggle} size="md" className="modal-question">
      <ModalBody className="p-0">
        <div className="modal-question-header">
          <h3 className="text-white text-center m-0">تأكيد</h3>
        </div>

        <div className="modal-main">
          
            <h3 className="text-purple py-3">هل انت متأكد انك تريد حذف حسابك؟</h3>

          <div className=" d-flex gap-4">
            <Button onClick={toggle} className="question-close-btn">
              يغلق
            </Button>
           
            <Button
              onClick={() => deleteMutation.mutate()}
              className="question-success-btn"> 

                {deleteMutation.isLoading ? "يعالج" : ' يضيف'}
                {deleteMutation.isLoading &&  <Spinner size="sm" className="flex-shrink-0 ms-2"/>}
              
            </Button>
          
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmDeleteModal;