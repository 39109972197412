export const formatDate = (date) => {

    const months = {
        '01': 'يناير',
        '02': 'فبراير',
        '03': 'مارس',
        '04': 'إبريل',
        '05': 'مايو',
        '06': 'يونيو',
        '07': 'يوليو',
        '08': 'أغسطس',
        '09': 'سبتمبر',
        '10': 'أكتوبر',
        '11': 'نوفمبر',
        '12': 'ديسمبر',
    }
    
    const spllitedDate = date.split('-')
    if (spllitedDate.length === 3 ){
        return `${months[spllitedDate[1]]} ${spllitedDate[2]} `
    }

    return date

}

export const formatFullDate = (date) => {

    const months = {
        '01': 'يناير',
        '02': 'فبراير',
        '03': 'مارس',
        '04': 'إبريل',
        '05': 'مايو',
        '06': 'يونيو',
        '07': 'يوليو',
        '08': 'أغسطس',
        '09': 'سبتمبر',
        '10': 'أكتوبر',
        '11': 'نوفمبر',
        '12': 'ديسمبر',
    }
    
    if (date) {
        const splittedDate = date.split('-');

        if (splittedDate.length === 3) {
            return `${splittedDate[2]} ${months[splittedDate[1]]} ${splittedDate[0]}`;
        }
    }

    return date

}

export const formatInputTime = (inputTime) => {

     // Split the input time into hours and minutes
  const [hours, minutes] = inputTime.split(':').map(Number);

  // Determine if it's AM or PM
  const period = hours >= 12 ? "مساءً" : "صباحا";

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Add leading zero to minutes if needed
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Construct the formatted time string without seconds
  const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;

  return formattedTime;
  }


export const arToEn = (date) => {
    var dateComponents = date.split('-');

    var year = dateComponents[0];
    var month = dateComponents[1];
    var day = dateComponents[2];

    var newDateString = `${day}-${month}-${year}`;

    return newDateString;
}