import React from "react";

import NavbarComponent from "../components/navbar.component";
import Footer from "../components/footer.component";

import { Link, useNavigate } from "react-router-dom";
import { Label, Input, Spinner } from "reactstrap";

import { loginSchema } from "../validation-schema";
import { useFormik } from "formik";
import { IP } from "../api/ip";
import axios from "axios";
import { useMutation } from "react-query";
import { useStore } from "../api/useStore";
import { toast } from "react-toastify";
import { handleScrollToTop } from "../utils/helperFunction";

const SignInPage = () => {
  document.title = "تسجيل الدخول | صناع السعادة";

  const navigate = useNavigate();

  const URL = `${IP}/api`;

  const sendLogin = (payload) => {
    return axios.post(`${URL}/accounts/candidate-login`, payload);
  };

  const sendLoginMutation = useMutation(sendLogin, {
    onSuccess: (response) => {
      const tokens = response?.data?.data;

      if (tokens) {
        useStore.setState({ userStatus: true });
        useStore.setState({ accessToken: tokens.access });
        useStore.setState({ refreshToken: tokens.refresh });

        toast.success("مرحباً");
        navigate("/");
      } else {
        toast.error("هناك خطأ ما");
      }
    },
    onError: (data) => {
      if (data?.response?.status === 400 && !!data?.response?.data?.message) {
        toast.error(data.response.data.message);
      } else if (
        data?.response?.status === 500 &&
        !!data?.response?.data?.message
      ) {
        toast.error(data.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = () => {
    sendLoginMutation.mutate(values);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: loginSchema,
      onSubmit,
      enableReinitialize: true,
    });

  return (
    <>
      <div className="auth-wrapper">
        <NavbarComponent activePage={"login"} />

        <div className="form-container">
          <div className="form">
            <h2 className="text-purple mb-2">تسجيل الدخول</h2>

            <form className="text-end" dir="rtl" onSubmit={handleSubmit}>
              <Label>البريد الإلكتروني </Label>

              <Input
                dir="rtl"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                className={
                  errors.email && touched.email
                    ? "form-control form-control-icon is-invalid login-field col-6"
                    : "form-control input-text form-control-icon login-field col-6"
                }
                id="emailInput"
                placeholder="أدخل البريد الإلكتروني"
                name="email"
              />
              {errors.email && touched.email && (
                <div dir="rtl" className="invalid-feedback">
                  {errors.email}
                </div>
              )}

              <Label>كلمة المرور</Label>
              <Input
                dir="rtl"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                className={
                  errors.password && touched.password
                    ? "form-control form-control-icon is-invalid login-field col-6"
                    : "form-control form-control-icon input-text login-field col-6"
                }
                id="passwordInput"
                placeholder="أدخل كلمة المرور"
                name="password"
              />
              {errors.password && touched.password && (
                <div dir="rtl" className="invalid-feedback">
                  {errors.password}
                </div>
              )}

              <div className="text-center">
                <button
                  type="submit"
                  className="main-btn"
                  disabled={sendLoginMutation.isLoading}
                >
                  {sendLoginMutation.isLoading ? "يعالج" : "تسجيل الدخول"}
                  {sendLoginMutation.isLoading && (
                    <Spinner size="sm" className="flex-shrink-0 ms-2" />
                  )}
                </button>
              </div>

              <div className="auth-bottom-text">
                <p>
                  هل نسيت كلمة السر؟
                  <Link className="me-1" to="/forgot-password">
                    إعادة ضبط
                  </Link>
                </p>
                <p>
                  ليس لديك حساب؟{" "}
                  <Link className="me-1" to="/sign-up">
                    {" "}
                    اشتراك
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer scroll={handleScrollToTop} />
    </>
  );
};

export default SignInPage;
