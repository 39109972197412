import {create} from "zustand";
import { persist, devtools } from "zustand/middleware";


export const useStore = create(
  devtools(
    persist(
      (set) => ({
        userStatus: false,
        accessToken: null,
        refreshToken: null,
      }),
      {
        name: "auth-storage",
        getStorage: () => localStorage,
      }
    )
  )
);