export const arabicCoords = (width, nameWidth, courseWidth, height) => {
    
    let courseFont = 14;

    let nameX = (width / 2) - (nameWidth / 2)
  
    let courseX = (width / 2) - (courseWidth / 2)
  
    
    if (courseWidth > 500) {
        courseFont = 12;
      }
    
    return {
        'nameX': nameX,
        'nameY': height - 200,

        'courseX': courseX,
        'courseY': 255,
  
        'startDateX': 270,
        'startDateY': 217,
  
        'endDateX': 138,
        'endDateY': 218,
  
        'hoursX': 310,
        'hoursY': 183,

        'courseFont': courseFont
    }
}


export const englishCoords = (width, nameWidth, courseWidth, height) => {
    
  let courseFont = 14;

  let nameX = (width / 2) - (nameWidth / 2)
  
  let courseX = (width / 2) - (courseWidth / 2)

  if (courseWidth > 500) {
      courseFont = 12;}
  
  return {
      'nameX': nameX,
      'nameY': height - 200,

      'courseX': courseX,
      'courseY': 270,

      'startDateX': 278,
      'startDateY': 225,

      'endDateX': 413,
      'endDateY': 225,

      'hoursX': 310,
      'hoursY': 183,

      'courseFont': courseFont
  }

}

export const vendorArabicCoords = (width, nameWidth, courseWidth, height) => {
    
    let courseFont = 14;

    let nameX = (width / 2) - (nameWidth / 2)
  
    let courseX = (width / 2) - (courseWidth / 2)
  
    
    if (courseWidth > 500) {
        courseFont = 12;
      }
    
    return {
        'nameX': nameX,
        'nameY': height - 200,

        'courseX': courseX,
        'courseY': 255,
  
        'startDateX': 300,
        'startDateY': 223,
  
        'endDateX': 180,
        'endDateY': 223,
  
        'hoursX': 340,
        'hoursY': 183,

        'courseFont': courseFont
    }
}


export const vendorEnglishCoords = (width, nameWidth, courseWidth, height) => {
    
  let courseFont = 14;

  let nameX = (width / 2) - (nameWidth / 2)
  
  let courseX = (width / 2) - (courseWidth / 2)

  if (courseWidth > 500) {
      courseFont = 12;
    }
  
  return {
      'nameX': nameX,
      'nameY': height - 210,

      'courseX': courseX,
      'courseY': 270,

      'startDateX': 222,
      'startDateY': 224,

      'endDateX': 407,
      'endDateY': 224,

      'hoursX': 280,
      'hoursY': 185,

      'courseFont': courseFont
  }

}