export const genders = [
  { label: "ذكر", value: "male" },
  { label: "أنثى", value: "female" },
];

export const nationalities = [
  { label: "سعودي", value: "saudi" },
  { label: "غير سعودي", value: "non-saudi" },
];

export const id_types = [
  { label: "هوية وطنية", value: "national_id" },
  { label: "إقامة", value: "iqama" },
];

export const qualifications = [
  { label: "ثانوي ", value: "secondary" },
  { label: "جامعي", value: "bachelors" },
  { label: "ماجستير", value: "masters" },
  { label: "أخرى", value: "phd" },
];

export const employment_statuses = [
  { label: "موظف", value: "employed" },
  { label: "غير موظف", value: "unemployed" },
  { label: "موظف موسمي", value: "seasonal-employee" },
  { label: "طالب", value: "student" },
];

export const cities = [
  { label: "الرياض", value: "riyadh" },
  { label: "جدة", value: "jeddah" },
  { label: "مكة المكرمة", value: "mecca" },
  { label: "المدينة المنورة", value: "medina" },
  { label: "ينبع", value: "yanbu" },
  { label: "الطائف", value: "taif" },
  { label: "الدمام", value: "dammam" },
  { label: "الظهران", value: "dhahran" },
  { label: "الخبر", value: "khobar" },
  { label: "الأحساء", value: "ahsa" },
  { label: "الجبيل", value: "jubail" },
  { label: "القطيف", value: "qatif" },
  { label: "حائل", value: "hail" },
  { label: "تبوك", value: "tabuk" },
  { label: "أبها", value: "abha" },
  { label: "الباحة", value: "baha" },
  { label: "جازان", value: "jazan" },
  { label: "نجران", value: "najran" },
  { label: "أخرى", value: "other" },
];

export const residences = [
  { label: "المنطقة الوسطى", value: "central" },
  { label: "المنطقة الشرقية", value: "eastern" },
  { label: "المنطقة الغربية", value: "western" },
  { label: "المنطقة الشمالية", value: "northern" },
  { label: "المنطقة الجنوبية", value: "southern" },
];


export const ages = [
  { label: "أقل من 20", value: 20 },
  { label: "20-30", value: 21 },
  { label: "31-40", value: 31 },
  { label: "41-50", value: 41 },
  { label: "51-60", value: 51 },
];

export const whereYouHearFrom = [
  { label: "موقع هيئة الترفيه", value: "website" },
  { label: "توصية من صديق", value: "friend" },
  { label: "لينكد ان", value: "linkedin" },
  { label: "منصات التواصل", value: "social-media" },
  { label: "أخرى", value: "other" },
];
