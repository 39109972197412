import React, { useState, useEffect } from "react";

import { PDFDocument, ReadingDirection, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import ListBtn from "../../assets/images/profilePage/list-btn.jpeg";

import axios from "axios";

import { useQuery, useMutation } from "react-query";

import { toast } from "react-toastify";

import useAxios from "../../api/useAxios";
import { formatFullDate } from "../../utils/formatter";
import ProfileTableRow from "./profile-table-row.component";
import ProfileCourseMobileView from "./profile-course-mobileView.component";
import ModalFeedback from "./modalFeedback.component";

const ProfileTable = ({ getQuestionnaire, refetchTable }) => {
  const [scheduleID, setScheduleID] = useState(false);


  const [feedbackModal, setFeedbackModal] = useState(false);

  const toggleFeedbackModal = () => {
    if (feedbackModal) {
      // setFetchFeedback(true);
      setScheduleID(false);
    }
    // setFetchFeedback(false);
    setFeedbackModal(!feedbackModal);
  };

  const api = useAxios();

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery("search", async () => api.get(`/courses/registrations`), {
    cacheTime: 0,
  });

  const registrations = response?.data?.registrations ?? [];


  useEffect(() => {
    if (refetchTable) {
      refetch();
    }
  }, [refetchTable]);

  useEffect(() => {
    if (scheduleID) {
      toggleFeedbackModal();
    }

  }, [scheduleID]);

  return (
    <>
      <div className="profile-table d-none d-md-block">
        <table>
          <tr>
            <th>إعطاء ردود الفعل</th>
            <th>شهادة</th>
            <th>تقييم ما بعد</th>
            <th>التقييم المسبق</th>
            <th>تاريخ</th>
            <th>اسم الدورة التدريبية</th>
          </tr>

          {isLoading ? (
            <tr>
              <td className="border-0"></td>
              <td className="border-0"></td>
              <td className="border-0 py-4">
                <div class="spinner-border secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
              <td className="border-0"></td>
              <td className="border-0"></td>
            </tr>
          ) : !registrations?.length > 0 ? (
            <tr>
              <td className="border-0"></td>
              <td className="border-0"></td>
              <td className="border-0"></td>
              <td className="border-0 py-4">
                <div class=" secondary" role="status">
                  لم يتم العثور على العنصر
                </div>
              </td>
              <td className="border-0"></td>
              <td className="border-0"></td>
            </tr>
          ) : (
            registrations.map((registration) => (
              <ProfileTableRow
                registration={registration}
                getQuestionnaire={getQuestionnaire}
                getScheduleID={setScheduleID}
                />
                ))
                )}
        </table>
      </div>
      <div className="d-md-none">
        {registrations?.map((registration) => (
          <ProfileCourseMobileView
            getScheduleID={setScheduleID}
            registration={registration}
            getQuestionnaire={getQuestionnaire}
          />
          // <div className="profile-course">
          //   <div className="profile-course-header"></div>
          //   <div className="profile-course-content p-3">
          //     <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          //       <h5
          //         className="text-purple"
          //         style={{
          //           width: "fit-content",
          //           textAlign: "right",
          //         }}
          //       >
          //         اسم الدورة التدريبية
          //       </h5>

          //       <p
          //         className="text-green mb-0"
          //         style={{
          //           width: "fit-content",
          //           textAlign: "left",
          //         }}
          //         dir="rtl"
          //       >
          //         {registration?.course_name ?? "N/A"}
          //       </p>
          //     </div>
          //     <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          //       <h5 className="text-purple">تاريخ</h5>
          //       <p dir="rtl" className="text-green">
          //         {registration?.start_date
          //           ? formatFullDate(registration.start_date)
          //           : "N/A"}
          //         <br />
          //         {registration?.end_date
          //           ? formatFullDate(registration.end_date)
          //           : "N/A"}
          //       </p>
          //     </div>
          //     <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          //       <h5 className="text-purple"> التقييم المسبق</h5>

          //       {/* {registration?.questionnaire_open ? (
          //         <img
          //           className="profil-list-btn"
          //           src={ListBtn}
          //           alt="btn"
          //           onClick={() =>
          //             getQuestionnaire({
          //               questionnaireID: registration.questionnaire_id,
          //               registrationID: registration.id,
          //             })
          //           }
          //         />
          //       ) : (
          //         <>
          //           <span className="text-green">التقييم غير متاح</span>
          //         </>
          //       )} */}
          //       {registration?.pre_assessment?.is_active &&
          //       !registration?.pre_assessment?.submitted ? (
          //         <img
          //           className="profil-list-btn"
          //           src={ListBtn}
          //           alt="btn"
          //           onClick={() =>
          //             getQuestionnaire({
          //               questionnaireID:
          //                 registration?.pre_assessment?.assessment,
          //               registrationID: registration?.pre_assessment?.id,
          //             })
          //           }
          //         />
          //       ) : registration?.pre_assessment?.submitted ? (
          //         <p
          //           className={`mb-0 ${
          //             registration?.pre_assessment?.is_pass
          //               ? "text-success"
          //               : "text-danger"
          //           }`}
          //         >
          //           {registration?.pre_assessment?.score}
          //         </p>
          //       ) : (
          //         <>
          //           <span className="text-green">التقييم غير متاح</span>
          //         </>
          //       )}
          //     </div>
          //     <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          //       <h5 className="text-purple">تقييم ما بعد</h5>

          //       {/* {registration?.questionnaire_open ? (
          //         <img
          //           className="profil-list-btn"
          //           src={ListBtn}
          //           alt="btn"
          //           onClick={() =>
          //             getQuestionnaire({
          //               questionnaireID: registration.questionnaire_id,
          //               registrationID: registration.id,
          //             })
          //           }
          //         />
          //       ) : (
          //         <>
          //           <span className="text-green">التقييم غير متاح</span>
          //         </>
          //       )} */}
          //       {registration?.post_assessment?.is_active &&
          //       !registration?.post_assessment?.submitted ? (
          //         <img
          //           className="profil-list-btn"
          //           src={ListBtn}
          //           alt="btn"
          //           onClick={() =>
          //             getQuestionnaire({
          //               questionnaireID:
          //                 registration?.post_assessment?.assessment,
          //               registrationID: registration?.post_assessment?.id,
          //             })
          //           }
          //         />
          //       ) : registration?.post_assessment?.submitted ? (
          //         <p
          //           className={`mb-0 ${
          //             registration?.post_assessment?.is_pass
          //               ? "text-success"
          //               : "text-danger"
          //           }`}
          //         >
          //           {registration?.post_assessment?.score}
          //         </p>
          //       ) : (
          //         <>
          //           <span className="text-green">التقييم غير متاح</span>
          //         </>
          //       )}
          //     </div>
          //     <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          //       <h5 className="text-purple">شهادة </h5>
          //       {registration?.post_assessment?.is_pass ? (
          //         <p
          //           className="mb-0 download-certificate"
          //           // onClick={downloadPdf(registration)}
          //         >
          //           تحميل الشهادة
          //         </p>
          //       ) : (
          //         <p className="mb-0 mx-4">-</p>
          //       )}
          //     </div>
          //     {/* <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          //       <h5 className="text-purple">نتيجة</h5>
          //       <p className="text-green">{registration?.score ?? "-"}</p>
          //     </div> */}
          //   </div>
          // </div>
        ))}
      </div>
      <ModalFeedback
        feedbackModal={feedbackModal}
        toggleFeedbackModal={toggleFeedbackModal}
        scheduleID={scheduleID}
        refetchRegistrations={refetch}
      />
    </>
  );
};

export default ProfileTable;
