import React from "react";

import geaColor from "../assets/images/gea-colored-logo.png";

import combinedLogoDark from "../assets/images/logos/combined-logo-dark.png";

import Wifi from "../assets/images/icons/social-icon.png";
import mail from '../assets/images/icons/mail-icon.png'
import phone from '../assets/images/icons/phone-icon.png'
import Facebook from "../assets/images/icons/facebook.png";
import Instagram from "../assets/images/icons/instagram.png";
import Twitter from "../assets/images/icons/twitter.png";
import Youtube from "../assets/images/icons/youtube.png";
// import Above from "../assets/images/icons/above@2x.png";


import Above from "../assets/images/icons/go-up.png";

import {
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";
import { FaC, FaInstagram, FaXTwitter, FaYoutube } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";

const Footer = ({ scroll }) => {

  const currentYear = new Date().getFullYear()

  return (
    <>
      <div className="footer-top mt-5">
        {/* <div className="footer-top-scroll"> */}
        <img
          src={Above}
          className="footer-top-scroll"
          onClick={() => scroll()}
        />
        {/* </div> */}
      </div>
      <div className="footer">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between gap-4 gap-sm-3 p-3 p-sm-5">
          <div className="footer-logo order-3 order-md-1">
            <img src={combinedLogoDark} />
          </div>
          <div className="order-2">
            <h3 className="fw-bold text-center mb-4">تواصل معنا</h3>

            <div className="d-flex justify-content-center align-items-center gap-3 my-3">
              <div className="footer-social-icon">
                <img className="social-icon" src={Wifi} />
              </div>
              <div className="footer-social-icon">
                <a href="https://www.instagram.com/gea_saudi/" target="_blank">
                  <img src={Instagram} />
                </a>
              </div>
              <div className="footer-social-icon">
                <a
                  href="https://www.youtube.com/channel/UCHJpU7eYVx5SUg48L31mfaQ"
                  target="_blank"
                >
                  <img src={Youtube} />
                </a>
              </div>
              <div className="footer-social-icon">
                <a href="https://twitter.com/gea_sa" target="_blank">
                  <img src={Twitter} />
                </a>
              </div>
              <div className="footer-social-icon">
                <img src={Facebook} />
              </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center gap-3">
              <div className="d-flex align-items-center gap-2">
                <img className="mail-phone text-primary-color fs-5" src={mail} />
                <p className="fs-5 mb-0 ">training_solutions@takamol.com.sa</p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <img className="mail-phone text-primary-color fs-5" src={phone} />
                <p className="fs-5 mb-0 fw-bold footer-cellno">
                8001282222
                </p>
              </div>
            </div>
          </div>
          <div className="footer-logo order-1 order-md-3">
            <img src={geaColor} />
          </div>
        </div>
        <hr className=" mb-0 footer-divider" />
        <div className="d-flex flex-column align-items-center justify-content-center p-3">
          <p className="mb-0">جميع الحقوق محفوظة © {currentYear} لصناع السعادة</p>
        </div>

        {/* <div className="contact-info py-5 text-white bg-dark"></div> */}
        {/* <div className="container d-none d-md-block">
        <div className="row justify-content-between align-items-center">

          <div className="col-6 d-none d-sm-block text-start">
            <img className="combined-logos" src={combinedLogos} alt="" />
          </div>


          <div className="col-6 text-end">
            <img className="gea-logo" src={geaColored} alt="" />
          </div>
        
        </div>
      </div>


      <div className="container d-block d-md-none">
        <div className="row justify-content-end">

            <div className="text-end">
              <img src={combinedLogos} className="combined-logos" alt="" />
            </div>
            <div className="col-auto mb-3 mb-md-5">
              <img className="gea-logo" src={geaColored} alt="" />
            </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row text-end">
          <h2 className="fw-bold text-white text-md-end text-center">تواصل معنا</h2>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5 social-and-contacts">

          <div className="col-lg-4 col-12 social-icons">
            <a className="me-4"
              href="https://www.linkedin.com/company/general-entertainment-authority/"
              target="_blank"
            >
              <div className="info-social">
                <FaLinkedinIn className="info-social-icon text-white fs-4" />
              </div>
            </a>
            <a className="me-4"
              href="https://www.youtube.com/channel/UCHJpU7eYVx5SUg48L31mfaQ"
              target="_blank"
            >
              <div className="info-social">
                <FaYoutube className="info-social-icon text-white fs-4" />
              </div>
            </a>
            <a className="me-4"
             href="https://twitter.com/gea_sa" target="_blank">
              <div className="info-social">
                <FaXTwitter className="info-social-icon text-white fs-4" />
              </div>
            </a>
            <a className="me-4"
              href="https://www.instagram.com/gea_saudi/"
              target="_blank"
            >
              <div className="info-social">
                <FaInstagram className="info-social-icon text-white fs-4" />
              </div>
            </a>
          </div>

          <div className="col-lg-8 col-12 contact-section">

              <span className="me-5">
                <FaPhoneAlt className="info-icon text-white me-2 fs-5"/>
                <p className="fs-5 mb-0 text-white">8001282222</p>
              </span>
              
              <span className="">
                <FaEnvelope className="info-icon text-white me-2 fs-5" />
                <p className="fs-5 mb-0 text-white">training_solutions@takamol.com.sa</p>
              </span>

          </div>
        </div>
      </div>

      <div className="container mt-2 mt-md-5">
          <h4 className="copyright" dir="rtl">©جميع الحقوق محفوظة لصناع السعادة 2023</h4>
      </div> */}
      </div>
    </>
  );
};

export default Footer;
