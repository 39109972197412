import React, { useState } from "react";
import { Input, Label } from "reactstrap";

const FeedbackMCQQuestion = ({ data, idx, simpleLength, answer }) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  const handleCheckboxChange = (checkboxId) => {
    setSelectedCheckbox(checkboxId === selectedCheckbox ? null : checkboxId);
    answer(data?.id, checkboxId);
  };

  return (
    <div className="text-end mb-4">
      <div className="d-flex align-items-start justify-content-end mb-1">
        <Label className="m-0">{data?.statement}</Label>
        <p className="ms-3 mb-0 text-purple">
          <b>السؤال{simpleLength + idx + 1}</b>
        </p>
      </div>
      <div className="mt-3">
        {data?.options?.map((op) => {
          return (
            <div className="question-checkboxes d-flex gap-3 justify-content-end mb-3">
              <Label className="m-0">{op?.option_text}</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                dir="rtl"
                name={`mcq-answer-${data?.id}`}
                checked={selectedCheckbox == op?.id}
                onChange={() => handleCheckboxChange(op?.id)}
              />{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeedbackMCQQuestion;
