import { Navigate } from "react-router-dom";
import { useStore } from "../api/useStore";


export const ProtectedRoute = ({ children }) => {


    const userStatus = useStore((state) => state.userStatus);
    
    return userStatus === true ? children : <Navigate to="/" />;

  }
  