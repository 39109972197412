import React, { useEffect, useState } from "react";
import "../assets/sass/profile.scss";

import NavbarComponent from "../components/navbar.component";
import { Container, Input, Label, Spinner } from "reactstrap";
import { ImPencil } from "react-icons/im";
import { FaCheck } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import Footer from "../components/footer.component";
import ProfileTable from "../components/profile/profileTable.component";
import ModalQuestion from "../components/profile/modalQuestion.component";

import { useQuery, useMutation } from "react-query";
import useAxios from "../api/useAxios";

import { useFormik } from "formik";
import { candidateSchema } from "../validation-schema";

import { toast } from "react-toastify";

import ConfirmDeleteModal from "../components/profile/confirm-delete-modal.component";
import ChangePasswordModal from "../components/profile/change-password-modal.component";
import { handleScrollToTop } from "../utils/helperFunction";

import {
  id_types,
  cities,
  employment_statuses,
  genders,
  nationalities,
  qualifications,
  residences,
  ages,
} from "../constants/signup-constants";

const Profile = () => {
  document.title = "حساب تعريفي | صناع السعادة";
  const [fetch, setFetch] = useState(null);

  const recieveFromModal = (func) => {
    setFetch(true);
  };

  const [enableFields, setEnableFields] = useState(true);
  const [modal, setModal] = useState(false);

  const [passwordModal, setPasswordModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const togglePassModal = () => {
    setPasswordModal(!passwordModal);
  };

  const [questionnaireID, setQuestionnaireID] = useState(false);

  const toggle = () => {
    if (modal) {
      setQuestionnaireID(false);
    }
    setModal(!modal);
  };

  const api = useAxios();

  const onSubmit = () => {

    updateProfileMutation.mutate(values);
  };

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery("profile", async () => api.get("/accounts/profile"));

  const profileData = response?.data?.profile ?? {};

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ar_name: profileData?.ar_name ?? "",
      en_name: profileData?.en_name ?? "",
      nationality: profileData?.nationality ?? "",
      phone: profileData?.phone ?? "",
      email: profileData?.email ?? "",
      id_type: profileData?.id_type ?? "",
      qualification: profileData?.qualification ?? "",
      employment_status: profileData?.employment_status ?? "",
      age: profileData?.age ?? "",
      gender: profileData?.gender ?? "",
      residence: profileData?.residence ?? "",
      city: profileData?.city ?? "",
      id_no: profileData?.id_no ?? "",
      organization: profileData?.organization ?? "",
      job_title: profileData?.job_title ?? "",
      years_of_experience: profileData?.years_of_experience ?? "",
    },
    validationSchema: candidateSchema,
    onSubmit,
    enableReinitialize: true,
  });



  const updateProfile = (payload) => {
    return api.patch(`/accounts/profile`, payload);
  };

  const updateProfileMutation = useMutation(updateProfile, {
    onSuccess: (response) => {
      toast.success("محدث");
      refetch();
      setEnableFields(true);
    },
    onError: (data) => {
      if (data?.response?.status === 400 && !!data?.response?.data?.message) {
        toast.error(data.response.data.message);
      } else if (
        data?.response?.status === 500 &&
        !!data?.response?.data?.message
      ) {
        toast.error(data.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const dismissForm = () => {
    setEnableFields(true);
    setFieldValue("name", profileData?.name ?? "");
    setFieldValue("city", profileData?.city ?? "");
    setFieldValue("country", profileData?.country ?? "");
  };

  const submitForm = () => {
    onSubmit();
  };

  // code for questionnaire

  useEffect(() => {
    if (questionnaireID) {
      toggle();
    }
  }, [questionnaireID]);

  const { data: questionnaireResponse, isLoading: questionnaireLoading } =
    useQuery(
      `questionnaire-${questionnaireID?.questionnaireID}`,
      async () =>
        api.get(`/courses/assessment/${questionnaireID?.questionnaireID}`),
      { cacheTime: 0, enabled: !!questionnaireID }
    );

  const questions = questionnaireResponse?.data?.assessment?.questions ?? [];

  return (
    <>
      <div className="profile-wrapper">
        <NavbarComponent activePage={"profile"} />
        <div className="d-flex justify-content-center">
          <div className="profile-heading">
            <h1 className="text-end text-white mt-5">الحساب التعريفي</h1>
          </div>
        </div>

        <div className="form-container pt-3">
          <div className="form profile-form">
            <form className="text-end" dir="rtl" action="">
              <div className="d-flex justify-content-end">
                {updateProfileMutation.isLoading ? (
                  <Spinner type="border" color="success" size="sm" />
                ) : (
                  <>
                    {enableFields && (
                      <ImPencil
                        className="text-green profile-edit-icon"
                        onClick={() => {
                          setEnableFields(false);
                        }}
                      />
                    )}

                    {!enableFields && (
                      <div className="d-flex gap-4 profile-actions">
                        <MdClose
                          className="action-icon text-danger"
                          onClick={dismissForm}
                        />
                        <FaCheck
                          className="action-icon text-green"
                          onClick={submitForm}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="row">

                <div className="col-12 col-md-6">
                  <Label>الإسم الكامل باللغة العربية</Label>
                  <Input
                    dir="rtl"
                    value={values.ar_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className={
                      errors.ar_name && touched.ar_name
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="nameInput"
                    placeholder="أدخل الاسم باللغة العربية"
                    name="ar_name"
                    disabled={enableFields}
                  />
                  {errors.ar_name && touched.ar_name && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.ar_name}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <Label>الإسم الكامل باللغة الانجليزية</Label>
                  <Input
                    dir="rtl"
                    value={values.en_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className={
                      errors.en_name && touched.en_name
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="en_nameInput"
                    placeholder="أدخل الاسم باللغة الإنجليزية"
                    name="en_name"
                    disabled={enableFields}
                  />
                  {errors.en_name && touched.en_name && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.en_name}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <Label>الجنسية</Label>
                  <Input
                    dir="rtl"
                    value={values.nationality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={
                      errors.nationality && touched.nationality
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="nationalityInput"
                    placeholder="أدخل الجنسية"
                    name="nationality"
                    disabled={enableFields}
                  >
                    <option value="">اختر الجنسية</option>
                    {nationalities.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                  {errors.nationality && touched.nationality && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.nationality}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <Label>العمر</Label>
                  <Input
                    dir="rtl"
                    value={values.age}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={
                      errors.age && touched.age
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="ageInput"
                    placeholder="أدخل العمر"
                    name="age"
                    disabled={enableFields}
                  >
                    <option value="">اختر العمر</option>
                    {ages.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>

                  {errors.age && touched.age && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.age}
                    </div>
                  )}
                </div>
            
                <div className="col-12 col-md-6">
                  <Label>رقم الجوال </Label>
                  <Input
                    dir="rtl"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    min={0}
                    className={
                      errors.phone && touched.phone
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="phoneInput"
                    placeholder="أدخل العمر"
                    name="phone"
                    disabled={enableFields}
                  />
                  {errors.phone && touched.phone && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.phone}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <Label>الجنس</Label>
                  <Input
                    dir="rtl"
                    value={values.gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={
                      errors.gender && touched.gender
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="genderInput"
                    placeholder="أدخل الجنس"
                    name="gender"
                    disabled={enableFields}
                  >
                    <option value="">اختر الجنس</option>
                    {genders.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                  {errors.gender && touched.gender && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.gender}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <Label>البريد الإلكتروني</Label>
                  <Input
                    dir="rtl"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    className={
                      errors.email && touched.email
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="emailInput"
                    placeholder="أدخل البريد الإلكتروني"
                    name="email"
                    disabled
                  />
                  {errors.email && touched.email && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.email}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  {" "}
                  <Label>نوع الوثيقة</Label>
                  <Input
                    dir="rtl"
                    value={values.id_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={
                      errors.id_type && touched.id_type
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="id_typeInput"
                    name="id_type"
                    disabled={enableFields}
                  >
                    <option value="">اختر نوع الهوية </option>
                    {id_types.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                  {errors.id_type && touched.id_type && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.id_type}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <Label>رقم الهوية</Label>
                  <Input
                    dir="rtl"
                    value={values.id_no}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    className={
                      errors.id_no && touched.id_no
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="id_noInput"
                    placeholder="أدخل رقم الهوية"
                    name="id_no"
                    disabled={enableFields}
                  />
                  {errors.id_no && touched.id_no && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.id_no}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <Label>المؤهل العلمي</Label>
                  <Input
                    dir="rtl"
                    value={values.qualification}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={
                      errors.qualification && touched.qualification
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="qualificationInput"
                    name="qualification"
                    disabled={enableFields}
                  >
                    <option value="">اختر المؤهل</option>
                    {qualifications.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                  {errors.qualification && touched.qualification && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.qualification}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <Label>الحالة الوظيفية</Label>
                  <Input
                    dir="rtl"
                    value={values.employment_status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={
                      errors.employment_status && touched.employment_status
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="employment_statusInput"
                    name="employment_status"
                    disabled={enableFields}
                  >
                    <option value="">اختر الحالة الوظيفية</option>
                    {employment_statuses.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                  {errors.employment_status && touched.employment_status && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.employment_status}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                <Label>مسمى وظيفي</Label>
                <Input
                  dir="rtl"
                  value={values.job_title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={enableFields}
                  type="text"
                  className={
                    errors.job_title && touched.job_title
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="job_titleInput"
                  name="job_title"
                  placeholder="أدخل المسمى الوظيفي
                  "
                />
                {errors.job_title && touched.job_title && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.job_title}
                  </div>
                )}
              </div>

                <div className="col-12 col-md-6">
                <Label>جهة العمل</Label>
                <Input
                  dir="rtl"
                  value={values.organization}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  disabled={enableFields}
                  className={
                    errors.organization && touched.organization
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="organizationInput"
                  name="organization"
                  placeholder="أدخل الاسم الجهة العمل"
                />
                {errors.organization && touched.organization && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.organization}
                  </div>
                )}
                </div>  
             
                <div className="col-12 col-md-6">
                  <Label>عدد سنوات الخبرة</Label>
                  <Input
                    dir="rtl"
                    value={values.years_of_experience}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className={
                      errors.years_of_experience && touched.years_of_experience
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="years_of_experienceInput"
                    name="years_of_experience"
                    placeholder="أدخل عدد سنوات الخبرة"
                    disabled={enableFields}
                  />
                  {errors.years_of_experience && touched.years_of_experience && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.years_of_experience}
                    </div>
                  )}
                </div>


              <div className="col-12 col-md-6">
                <Label>مكان الإقامة</Label>
                <Input
                  dir="rtl"
                  value={values.residence}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.residence && touched.residence
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="residenceInput"
                  name="residence"
                  disabled={enableFields}
                >
                  <option value="">اختر مكان الإقامة</option>
                  {residences.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                {errors.residence && touched.residence && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.residence}
                  </div>
                )}
              </div>



                <div className="col-12 col-md-6">
                  <Label>مكان الإقامة</Label>
                  <Input
                    dir="rtl"
                    value={values.residence}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={
                      errors.residence && touched.residence
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="residenceInput"
                    name="residence"
                    disabled={enableFields}
                  >
                    <option value="">اختر مكان الإقامة</option>
                    {residences.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                  {errors.residence && touched.residence && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.residence}
                    </div>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <Label>المدينة</Label>
                  <Input
                    dir="rtl"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={
                      errors.city && touched.city
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="cityInput"
                    name="city"
                    disabled={enableFields}
                  >
                    <option value="">اختر المدينة</option>
                    {cities.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                  {errors.city && touched.city && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.city}
                    </div>
                  )}
                </div>
              </div>
            </form>
            {!enableFields && (
              <div className="d-flex mt-4">
                <button onClick={togglePassModal} className="purple-btn me-2">
                  تطوير كلمة السر
                </button>
                {/* <button onClick={toggleDeleteModal} className="delete-btn">
                  حذف الحساب
                </button> */}
              </div>
            )}
          </div>
        </div>

        <Container className="py-5">
          <h2 className="text-end text-white mb-3">تسجيل</h2>
          <ProfileTable
            getQuestionnaire={setQuestionnaireID}
            refetchTable={fetch}
          />
        </Container>
      </div>
      <Footer scroll={handleScrollToTop} />

      <ModalQuestion
        questions={questions}
        modal={modal}
        toggle={toggle}
        questionnaireID={questionnaireID}
        refetch={recieveFromModal}
        questionnaireLoading={questionnaireLoading}
      />

      <ConfirmDeleteModal modal={deleteModal} toggle={toggleDeleteModal} />

      <ChangePasswordModal modal={passwordModal} toggle={togglePassModal} />
    </>
  );
};

export default Profile;
