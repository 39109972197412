import React, { useState } from "react";

import NavbarComponent from "../components/navbar.component";
import Footer from "../components/footer.component";

import { Input, Label, Spinner } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { resetPasswordSchema } from "../validation-schema";

import { IP } from "../api/ip";

import axios from "axios";
import { toast } from "react-toastify";
import { handleScrollToTop } from "../utils/helperFunction";

const ResetPasswordPage = () => {
  document.title = "الملف الشخصي | صناع السعادة";

  const URL = `${IP}/api`;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userToken = queryParams.get("token");

  const [showLoginBtn, setLoginBtn] = useState(false);

  const sendPassword = (payload) => {
    return axios.post(
      `${URL}/accounts/password_reset/confirm/?token=${userToken}`,
      payload
    );
  };

  const sendPasswordMutation = useMutation(sendPassword, {
    onSuccess: (response) => {
      setLoginBtn(true);
    },
    onError: (response) => {
      if (
        response?.response?.data?.password &&
        Array.isArray(response.response.data.password)
      ) {
        const error = response.response.data.password.join(". ");
        toast.error(error);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = () => {
    sendPasswordMutation.mutate(values);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        password: "",
        confirmPassword: "",
        token: userToken,
      },
      validationSchema: resetPasswordSchema,
      onSubmit,
      enableReinitialize: true,
    });

  return (
    <>
      <div className="auth-wrapper forgot-password-wrapper">
        <NavbarComponent />

        <div className="form-container">
          <div className="form">
            {showLoginBtn ? (
              <>
                <h2 className="text-purple mb-4">يمكنك تسجيل الدخول الآن</h2>
                <Link to={"/sign-in"}>
                  <button className="main-btn">تسجيل الدخول</button>
                </Link>
              </>
            ) : (
              <>
                <h2 className="text-purple mb-4">اعد ضبط كلمه السر</h2>

                <form className="text-end" dir="rtl" onSubmit={handleSubmit}>
                  <Label>كلمة المرور الجديدة</Label>
                  <Input
                    dir="rtl"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    className={
                      errors.password && touched.password
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="passwordInput"
                    placeholder="أدخل كلمة المرور الجديدة  "
                    name="password"
                  />
                  {errors.password && touched.password && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.password}
                    </div>
                  )}

                  <Label> تأكيد كلمة المرو </Label>
                  <Input
                    dir="rtl"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? "form-control form-control-icon is-invalid login-field col-6"
                        : "form-control input-text form-control-icon login-field col-6"
                    }
                    id="confirmPasswordInput"
                    placeholder="أدخل كلمة المرور الجديدة  "
                    name="confirmPassword"
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <div dir="rtl" className="invalid-feedback">
                      {errors.confirmPassword}
                    </div>
                  )}

                  <div className="text-center">
                    <button
                      type="submit"
                      className="main-btn"
                      disabled={sendPasswordMutation.isLoading}
                    >
                      {sendPasswordMutation.isLoading ? "يعالج" : "يحفظ"}
                      {sendPasswordMutation.isLoading && (
                        <Spinner size="sm" className="flex-shrink-0 ms-2" />
                      )}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer scroll={handleScrollToTop} />
    </>
  );
};

export default ResetPasswordPage;
