import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";

const FeedbackSimpleQuestion = ({ data, idx, answer }) => {
  const [simpleAnswer, setSimpleAnswer] = useState("");

  useEffect(() => {
    answer(data?.id, "");
  }, []);

  return (
    <div className="text-end mb-4">
      <div className="d-flex align-items-start justify-content-end mb-1">
        <Label className="m-0">{data?.statement}</Label>
        <p className="ms-3 mb-0 text-purple">
          <b>السؤال{idx + 1}</b>
        </p>
      </div>
      <Input
        type="textarea"
        dir="rtl"
        name={`simple-answer-${data?.id}`}
        value={simpleAnswer}
        onChange={(e) => {
          setSimpleAnswer(e.target.value);
          answer(data?.id, e.target.value);
        }}
      />
    </div>
  );
};

export default FeedbackSimpleQuestion;
