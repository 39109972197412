import React, { useEffect, useRef } from "react";
import axios from "axios";

import { useQuery, useMutation } from "react-query";

import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

import { IP } from "../api/ip";
import useAxios from "../api/useAxios";
import { useStore } from "../api/useStore";
import { formatFullDate } from "../utils/formatter";
import { formatInputTime } from "../utils/formatter";


const ScheduleTable = ({ homeValues, data, searchLoading, showResponse }) => {
  const api = useAxios();

  const URL = `${IP}/api`;

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(
    "schedules",
    async () =>
      axios.get(`${URL}/courses/schedules/?course=${homeValues?.course ?? ""}`),
    {
      cacheTime: 0,
    }
  );

  const schedules = response?.data?.schedules ?? [];


  const userStatus = useStore((state) => state?.userStatus);

  const toastId = useRef(null);

  const notify = () => (toastId.current = toast("التسجيل! انتظر من فضلك"));

  const dismiss = () => toast.dismiss(toastId.current);

  const sendRegister = (payload) => {
    notify();
    return api.post(`/courses/register-course`, payload);
  };

  const sendRegisterMutation = useMutation(sendRegister, {
    onSuccess: () => {
      dismiss();
      // toast.success("شكرا لتسجيلكم في الدورة ، عند قبولكم في الدورة سيتم ارسال بريد الكتروني بالقبول");
      showResponse()
    },
    onError: (data) => {
      dismiss();
      if (data?.response?.status === 400 && !!data?.response?.data?.message) {
        toast.error(data.response.data.message);
      } else if (
        data?.response?.status === 500 &&
        !!data?.response?.data?.message
      ) {
        toast.error(data.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });



  useEffect(() => {
    var profileTableDiv = document.querySelector('.profile-table');
  profileTableDiv.scrollLeft = profileTableDiv.scrollWidth;
  }, [])

  return (
    <>
      <div className="profile-table d-none d-md-block">
        <table>
          <tr>
            <th>التسجيل</th>
            <th>عدد الساعات</th>
            <th>اللغة المقدمة</th>
            <th>المدة</th>
            <th>الموعد</th>
            <th>الموقع</th>
            <th>اسم الدورة التدريبية</th>
          </tr>

          {isLoading || searchLoading ? (
            <tr>
              <td className="border-0"></td>
              <td className="border-0"> </td>
              <td className="border-0"> </td>
              <td className="border-0 py-3">
                <div class="spinner-border text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
              <td className="border-0"></td>
              <td className="border-0"></td>
              <td className="border-0"></td>
            </tr>
          ) : data && !data?.length > 0 ? (
            <tr>
              <td className="border-0"></td>
              <td className="border-0"> </td>
              <td className="border-0"> </td>
              <td className="border-0 py-3">
                <div class=" text-secondary">غير معثور عليه</div>
              </td>
              <td className="border-0"></td>
              <td className="border-0"></td>
              <td className="border-0"></td>
            </tr>
          ) : data ? (
            data.map((schedule) => (
              <tr>
                <td className="first-row">
                  {
                  userStatus ? (
                    <button
                      disabled={sendRegisterMutation.isLoading}
                      onClick={() =>
                        sendRegisterMutation.mutate({
                          scheduleID: schedule?.id,
                        })
                      }
                      className="table-register-btn"
                    >
                      التسجيل
                    </button>
                  ) : (
                    <button
                      disabled={sendRegisterMutation.isLoading}
                      onClick={() => toast.error("الرجاء تسجيل الدخول للتقديم")}
                      className="table-register-btn"
                    >
                      التسجيل
                    </button>
                  )}
                </td>

                <td className="first-row" dir="rtl">
                {schedule?.hours ?? "-"} ساعة
                </td>
                <td className="first-row" dir="rtl">
                  {schedule?.language ?? "-"}{" "}
                </td>
                <td className="first-row" dir="rtl">
                  {schedule?.duration ?? "-"} أيام
                </td>

                <td dir="rtl" className="first-row">
                  {schedule?.start_date
                    ? formatFullDate(schedule.start_date)
                    : "-"}
                  <br />
                  {schedule?.end_date ? formatFullDate(schedule.end_date) : "-"}
                  <br />
                  {schedule?.start_time
                    ? formatInputTime(schedule.start_time)
                    : ""}{" "}
                  -{" "}
                  {schedule?.end_time ? formatInputTime(schedule.end_time) : ""}
                </td>

                <td className="first-row">{schedule?.location?.name ?? "-"}</td>
                <td className="first-row">{schedule?.course?.name ?? "-"}</td>
              </tr>
            ))
          ) : schedules && !schedules?.length > 0 ? (
            <tr>
              <td className="border-0"></td>
              <td className="border-0"> </td>
              <td className="border-0"> </td>
              <td className="border-0 py-3">
                <div class=" text-secondary">غير معثور عليه</div>
              </td>
              <td className="border-0"></td>
              <td className="border-0"></td>
              <td className="border-0"></td>
            </tr>
          ) : (
            schedules?.map((schedule) => (
              <tr>
                <td className="first-row">
                  {
                    
                    schedule.status === 'Inactive'                       
                    ?
                    <p>قريباً
                    </p>
                  :
                  userStatus ? (
                    <button
                      disabled={sendRegisterMutation.isLoading}
                      onClick={() =>
                        sendRegisterMutation.mutate({
                          scheduleID: schedule?.id,
                        })
                      }
                      className="table-register-btn"
                    >
                      التسجيل
                    </button>
                  ) : (
                    <button
                      disabled={sendRegisterMutation.isLoading}
                      onClick={() => toast.error("الرجاء تسجيل الدخول للتقديم")}
                      className="table-register-btn"
                    >
                      التسجيل
                    </button>
                  )}
                </td>

                <td className="first-row" dir="rtl">
                  {" "}
                  {schedule?.hours ?? "-"} ساعة
                </td>
                <td className="first-row">{schedule?.language ?? "-"} </td>
                <td className="first-row" dir="rtl">
                  {" "}
                  {schedule?.duration ?? "-"} أيام
                </td>

                <td dir="rtl" className="first-row">
                  {schedule?.start_date
                    ? formatFullDate(schedule.start_date)
                    : "-"}
                  <br />
                  {schedule?.end_date ? formatFullDate(schedule.end_date) : "-"}
                  <br />
                  {schedule?.start_time
                    ? formatInputTime(schedule.start_time)
                    : ""}{" "}
                  -{" "}
                  {schedule?.end_time ? formatInputTime(schedule.end_time) : ""}
                </td>

                <td className="first-row">{schedule?.location?.name ?? "-"}</td>
                <td className="first-row">{schedule?.course?.name ?? "-"}</td>
              </tr>
            ))
          )}
        </table>
        <div className="profile-pagination d-flex justify-content-start align-items-center mt-4 gap-3"></div>
      </div>

      {/* MOBILE TABLE */}

      <div className="d-md-none">
        {isLoading || searchLoading ? (
          <div className="d-flex align-items-center justify-content-center my-5 py-5">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : data && !data?.length > 0 ? (
          <div className="d-flex align-items-center justify-content-center my-5 py-5">
            <div class="text-secondary" role="status">
              غير معثور عليه
            </div>
          </div>
        ) : data ? (
          data?.map((schedule) => {
            return (
              <div className="profile-course">
                <div className="profile-course-header"></div>
                <div className="profile-course-content p-3 mb-1 less-top-border">
                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3 gap-2">
                    <h5 className="text-purple text-end">
                      اسم الدورة التدريبية
                    </h5>
                    <p className="text-green text-start">
                      {schedule?.course?.name ?? "-"}
                    </p>
                  </div>
                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3 gap-3">
                    <h5 className="text-purple text-end">موقع</h5>
                    <p className="text-green text-start">
                      {schedule?.location?.name ?? "-"}
                    </p>
                  </div>

                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
                    <h5 className="text-purple text-end">الموعد</h5>
                    <p dir="rtl" className="text-green text-start">
                      {schedule?.start_date
                        ? formatFullDate(schedule.start_date)
                        : "-"}
                      <br />
                      {schedule?.end_date
                        ? formatFullDate(schedule.end_date)
                        : "-"}
                      <br />
                      {schedule?.start_time
                        ? formatInputTime(schedule.start_time)
                        : ""}{" "}
                      -{" "}
                      {schedule?.end_time
                        ? formatInputTime(schedule.end_time)
                        : ""}
                    </p>
                  </div>

                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
                    <h5 className="text-purple text-end">مدة</h5>
                    <p className="text-green text-start" dir="rtl">
                      أيام {schedule?.duration ?? "-"}
                    </p>
                  </div>
                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
                    <h5 className="text-purple text-end">اللغة المقدمة</h5>
                    <p className="text-green text-start">
                      {schedule?.language ?? "-"}
                    </p>
                  </div>

                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
                    <h5 className="text-purple text-end">عدد الساعات </h5>
                    <p className="text-green text-start" dir="rtl">
                      ساعة {schedule?.hours ?? "-"}
                    </p>
                  </div>

                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
                    <h5 className="text-purple text-end">التسجيل</h5>
                    {
                     schedule.status === 'Inactive'
                     ?
                     <p>قريباً
                     </p>
                    :
                    userStatus ? (
                      <button
                        disabled={sendRegisterMutation.isLoading}
                        onClick={() =>
                          sendRegisterMutation.mutate({
                            scheduleID: schedule?.id,
                          })
                        }
                        className="table-register-btn"
                      >
                        التسجيل
                      </button>
                    ) : (
                      <button
                        disabled={sendRegisterMutation.isLoading}
                        onClick={() =>
                          toast.error("الرجاء تسجيل الدخول للتقديم")
                        }
                        className="table-register-btn"
                      >
                        التسجيل
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : schedules && !schedules?.length > 0 ? (
          <div className="d-flex align-items-center justify-content-center my-5 py-5">
            <div class="text-secondary" role="status">
              غير معثور عليه
            </div>
          </div>
        ) : (
          schedules?.map((schedule) => {
            return (
              <div className="profile-course">
                <div className="profile-course-header"></div>
                <div className="profile-course-content p-3 mb-1 less-top-border">
                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3 gap-2">
                    <h5 className="text-purple text-end">
                      اسم الدورة التدريبية
                    </h5>
                    <p className="text-green text-start">
                      {schedule?.course?.name ?? "-"}
                    </p>
                  </div>
                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3 gap-3">
                    <h5 className="text-purple text-end">موقع</h5>
                    <p className="text-green text-start">
                      {schedule?.location?.name ?? "-"}
                    </p>
                  </div>

                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
                    <h5 className="text-purple text-end">الموعد</h5>
                    <p dir="rtl" className="text-green text-start">
                      {schedule?.start_date
                        ? formatFullDate(schedule.start_date)
                        : "-"}
                      <br />
                      {schedule?.end_date
                        ? formatFullDate(schedule.end_date)
                        : "-"}
                      <br />
                      {schedule?.start_time
                        ? formatInputTime(schedule.start_time)
                        : ""}{" "}
                      -{" "}
                      {schedule?.end_time
                        ? formatInputTime(schedule.end_time)
                        : ""}
                    </p>
                  </div>

                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
                    <h5 className="text-purple text-end">مدة</h5>
                    <p className="text-green text-start" dir="rtl">
                      {schedule?.duration ?? "-"} أيام
                    </p>
                  </div>
                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
                    <h5 className="text-purple text-end">اللغة المقدمة</h5>
                    <p className="text-green text-start">
                      {schedule?.language ?? "-"}
                    </p>
                  </div>

                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
                    <h5 className="text-purple text-end">عدد الساعات </h5>
                    <p className="text-green text-start" dir="rtl">
                      {schedule?.hours ?? "-"} ساعة
                    </p>
                  </div>

                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
                    <h5 className="text-purple text-end">التسجيل</h5>
                    {
                    schedule.status === 'Inactive'
                    ?
                    <p className="text-green text-start">قريباً
                    </p>
                   :userStatus ? (
                      <button
                        disabled={sendRegisterMutation.isLoading}
                        onClick={() =>
                          sendRegisterMutation.mutate({
                            scheduleID: schedule?.id,
                          })
                        }
                        className="register-btn"
                      >
                        التسجيل
                      </button>
                    ) : (
                      <button
                        disabled={sendRegisterMutation.isLoading}
                        onClick={() =>
                          toast.error("الرجاء تسجيل الدخول للتقديم")
                        }
                        className="register-btn"
                      >
                        التسجيل
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default ScheduleTable;
