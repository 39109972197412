import React, { useEffect } from "react";

import {Button, Modal, ModalBody, Spinner, Label, Input} from "reactstrap";

import useAxios from "../../api/useAxios";

import { toast } from 'react-toastify'
import { useMutation } from 'react-query';
import { useStore } from "../../api/useStore";

import { passwordSchema } from "../../validation-schema";

import { useFormik } from 'formik'


const ChangePasswordModal = ({ modal, toggle  }) => {

  const api = useAxios()

  const updatePassword = (payload) => {
    return api.post(`/accounts/change-password`, payload)
}


useEffect(() => {
  if (!modal){
      resetForm()
  }
}, [modal])

const updatePasswordMutation = useMutation(updatePassword, {
  
  onSuccess: () => {
        toast.success('محدث')
        resetForm()
        toggle()
    },

    onError: (response) => {


        if (response?.response?.status == 404 && response?.response?.data?.message){
            toast.error(response.response.data.message)
        }
        else{
            toast.error('هناك خطأ ما')
        }

    }
})

    const onSubmit = () => {
      updatePasswordMutation.mutate(values)
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        old_password: "",
        new_password: "",
      },
      validationSchema: passwordSchema,
      onSubmit,
      enableReinitialize: true,
    });


  return (
    <Modal isOpen={modal} toggle={toggle} size="md" className="modal-question">

      <ModalBody className="p-0">
      <div className="modal-question-header">
          <h3 className="text-white text-center m-0">تغيير كلمة المرور</h3>
        </div>
        <div className="modal-main">
          
            <form className="text-end" dir="rtl" onSubmit={handleSubmit}>
              <Label className="mt-0">كلمة المرور القديمة</Label>

              <Input
                dir="rtl"
                value={values.old_password}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                className={errors.old_password && touched.old_password ? "form-control form-control-icon is-invalid login-field col-6" : "form-control form-control-icon input-text login-field col-6"}
                id="oldPasswordInput"
                placeholder="أدخل كلمة المرور القديمة"
                name="old_password"
              />
                {
                    errors.old_password && touched.old_password && <div dir='rtl' className="invalid-feedback">{errors.old_password}</div>
                }


              <Label>كلمة المرور الجديدة</Label>
              
              <Input
                dir="rtl"
                value={values.new_password}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                className={errors.new_password && touched.new_password ? "form-control form-control-icon is-invalid login-field col-6" : "form-control form-control-icon input-text login-field col-6"}
                id="newPasswordInput"
                placeholder="أدخل كلمة المرور الجديدة"
                name="new_password"
              />
              {
                  errors.new_password && touched.new_password && <div dir='rtl' className="invalid-feedback">{errors.new_password}</div>
              }
            
            <div className="mt-3 text-start">
              <button type="button" onClick={toggle} className="delete-btn ms-2">يغلق</button>
               
              <Button
                type='submit'
                className="purple-btn"
                onSubmit={() => updatePasswordMutation.mutate(values)}
                > 
                {updatePasswordMutation.isLoading ? "يعالج" : '  تحديث'}
                {updatePasswordMutation.isLoading &&  <Spinner size="sm" className="flex-shrink-0 ms-2"/>}
              
            </Button>

            </div>
            </form>
            
           

          </div>

      </ModalBody>
    </Modal>
  );
};

export default ChangePasswordModal;