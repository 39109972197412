import React from "react";

import { Button, Modal, ModalBody } from "reactstrap";

import Question from "./question.component";

import { toast } from "react-toastify";

import { useMutation } from "react-query";

import useAxios from "../../api/useAxios";

const ModalQuestion = ({
  modal,
  toggle,
  questions,
  questionnaireID,
  refetch,
  questionnaireLoading,
}) => {
  let arr = [];
  let api = useAxios();

  const handleAnswer = (ans, qId) => {
    let index = arr.findIndex((item) => item.qId === qId);
    if (index !== -1) {
      arr[index] = { qId, ans };
    } else {
      arr.push({ qId, ans });
    }
  };

  const checkSubmit = () => {
    if (arr.length == questions.length) {
      submitMutation.mutate(arr);
    } else {
      toast.error("يرجى محاولة جميع الأسئلة");
    }
  };

  const submit = (payload) => {
    return api.post(
      `/courses/submit-answers/${questionnaireID?.registrationID}`,
      payload
    );
  };

  const submitMutation = useMutation(submit, {
    onSuccess: (response) => {
      toast.success("مُقَدَّم");
      toggle();
      refetch();
    },
    onError: (data) => {
      if (data?.response?.status === 400 && !!data?.response?.data?.message) {
        toast.error(data.response.data.message);
      } else if (
        data?.response?.status === 500 &&
        !!data?.response?.data?.message
      ) {
        toast.error(data.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg" className="modal-question">
      <ModalBody className="p-0">
        <div className="modal-question-header">
          <h3 className="text-white text-center m-0">استبيان</h3>
        </div>
        <div className="modal-main">
          <form>
            {questionnaireLoading ? (
              <div className="py-5">
                <div class="spinner-border text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              questions.map((quest, idx) => {
                return (
                  <Question quest={quest} idx={idx} answer={handleAnswer} />
                );
              })
            )}
          </form>
          <div className=" d-flex gap-4">
            <Button className="question-success-btn" onClick={checkSubmit}>
              {submitMutation.isLoading ? (
                <div
                  class="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "يُقدِّم"
              )}
            </Button>
            <Button onClick={toggle} className="question-close-btn">
              يغلق
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalQuestion;
