import React, { useState } from "react";

const Question = ({ idx, answer, quest }) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  const handleCheckboxChange = (checkboxId) => {
    setSelectedCheckbox(checkboxId === selectedCheckbox ? null : checkboxId);
    answer(checkboxId, quest.id);
  };
  return (
    <div className="d-lg-flex flex-row-reverse justify-content-end align-items-start gap-3 gap-md-5 mb-5">
      <div
        style={{ flex: "1" }}
        className="d-flex justify-content-end justify-content-lg-start mt-1"
      >
        <h4 className="text-purple text-end">السؤال رقم {idx + 1}</h4>
      </div>
      <div style={{ flex: "4" }}>
        <div className="question w-100">
          <p className="mb-0 text-end">{quest?.statement}</p>
        </div>
        <div className="question-checkboxes w-100 d-flex flex-column align-items-end justify-content-sm-between align-items-sm-center flex-sm-row-reverse">
          <div className="d-flex align-items-center gap-2 mt-3">
            <label dir='rtl' className="mt-0">{quest?.option1}</label>
            <input
              className="form-check-input"
              type="checkbox"
              name="a"
              checked={selectedCheckbox === "option1"}
              onChange={() => handleCheckboxChange("option1")}
            ></input>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3">
            <label dir='rtl' className="mt-0">{quest?.option2}</label>
            <input
              className="form-check-input"
              type="checkbox"
              name="b"
              checked={selectedCheckbox === "option2"}
              onChange={() => handleCheckboxChange("option2")}
            ></input>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3">
            <label dir='rtl' className="mt-0">{quest?.option3}</label>
            <input
              className="form-check-input"
              type="checkbox"
              name="c"
              checked={selectedCheckbox === "option3"}
              onChange={() => handleCheckboxChange("option3")}
            ></input>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3">
            <label dir='rtl' className="mt-0">{quest?.option4}</label>
            <input
              className="form-check-input"
              type="checkbox"
              name="d"
              checked={selectedCheckbox === "option4"}
              onChange={() => handleCheckboxChange("option4")}
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
