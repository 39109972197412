import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import ARcertificate from "../assets/certificate/arabic_certificate.pdf";
import ENCertificate from "../assets/certificate/english_certificate.pdf";
import customFontFile from "../assets/certificate/Tajawal-Regular.ttf";

import fhmCertificate from '../assets/certificate/fhm.pdf'
import esmCertificate from '../assets/certificate/esm.pdf'
import eventAcademyCertificate from '../assets/certificate/event-academy.pdf'
import fennecCertificate from '../assets/certificate/fennec.pdf'


import { vendorArabicCoords, vendorEnglishCoords } from "./coords-generator";
import { arToEn } from "./formatter";

const vendorCreateCertificate = async (registration) => {

  // english: esm, event academy
  // arabic: fhm, fennec

  var language = "arabic";

  const trainer = registration?.trainer
  

  try {
    let pdfBytes;

    if (trainer == "ESM") {
      language = "english"
      pdfBytes = await fetch(esmCertificate).then((res) => res.arrayBuffer());
    } 
    
    else if (trainer == "Event Academy") {
      language = "english";
      pdfBytes = await fetch(eventAcademyCertificate).then((res) => res.arrayBuffer());
    }

    else if (trainer == "Fhm") {
      pdfBytes = await fetch(fhmCertificate).then((res) => res.arrayBuffer());
    }
    
    else if (trainer == "Fennec") {
      pdfBytes = await fetch(fennecCertificate).then((res) => res.arrayBuffer());
    }

    const pdfDoc = await PDFDocument.load(pdfBytes);
    pdfDoc.registerFontkit(fontkit);

    const customFontBytes = await fetch(customFontFile).then((res) =>
      res.arrayBuffer()
    );
    const customFont = await pdfDoc.embedFont(customFontBytes);
    const [firstPage] = pdfDoc.getPages();

    const arName = registration?.candidate?.ar_name;
    const enName = registration?.candidate?.en_name;
    const course = registration?.course_name;
    let startDate = registration?.start_date;
    let endDate = registration?.end_date;

    var hours = registration?.course_hours.toString();

    const fontSize14 = 14;

    const { width, height } = firstPage.getSize();

    const arNameWidth = customFont.widthOfTextAtSize(arName, fontSize14);
   
    let enNameWidth;

    if (
      Object.keys(registration.candidate).includes("en_name") &&
      registration?.candidate?.en_name != null &&
      (trainer == "ESM" || trainer == "Event Academy")
    ) {
      enNameWidth = customFont.widthOfTextAtSize(enName, fontSize14);
    } else {
      enNameWidth = customFont.widthOfTextAtSize(arName, fontSize14);
    }

    const courseWidth = customFont.widthOfTextAtSize(course, fontSize14);

    let coords;

    if (language == "arabic") {
      coords = vendorArabicCoords(width, arNameWidth, courseWidth, height);
    } else {
      coords = vendorEnglishCoords(width, enNameWidth, courseWidth, height);
      startDate = arToEn(startDate);
      endDate = arToEn(endDate);
    }

    if (language === 'english'){
      hours = `${hours} Hours`
    }
   
   var hours_arabic = "ساعة"

    if (
      Object.keys(registration.candidate).includes("en_name") &&
      registration?.candidate?.en_name != null &&
      (trainer == "ESM" || trainer == "Event Academy")
    ) {
      firstPage.drawText(enName, {
        x: coords.nameX,
        y: coords.nameY,
        font: customFont,
        size: fontSize14,
        color: rgb(0, 0, 0),
      });
    } else {

      firstPage.drawText(arName, {
        x: coords.nameX,
        y: coords.nameY,
        font: customFont,
        size: fontSize14,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(hours_arabic, {
        x: coords.hoursX - 35,
        y: coords.hoursY,
        font: customFont,
        size: fontSize14,
        color: rgb(0, 0, 0),
      });
    }

    firstPage.drawText(course, {
      x: coords.courseX,
      y: coords.courseY,
      font: customFont,
      size: coords.courseFont,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(startDate, {
      x: coords.startDateX,
      y: coords.startDateY,
      font: customFont,
      size: fontSize14,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(endDate, {
      x: coords.endDateX,
      y: coords.endDateY,
      font: customFont,
      size: fontSize14,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(hours, {
      x: coords.hoursX,
      y: coords.hoursY,
      font: customFont,
      size: fontSize14,
      color: rgb(0, 0, 0),
    });

    return pdfDoc.save();
  } catch (error) {
    console.error("Error creating PDF:", error);
    return null;
  }
};

export default vendorCreateCertificate;
