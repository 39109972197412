import React from "react";

import CardBg from "../../assets/images/courses/course-card.png";
import Marker from "../../assets/images/icons/location@2x.png";
import Calender from "../../assets/images/icons/calender@2x.png";
import Clock from "../../assets/images/icons/clock@2x.png";
// import Marker from "../../assets/images/courses/icon-location-01.png";
// import Calender from "../../assets/images/courses/icon-date.png";
// import Clock from "../../assets/images/courses/icon-clock-01.png";

import { Link } from "react-router-dom";

import { formatFullDate, formatInputTime } from "../../utils/formatter";

const CourseCard = ({ course }) => {
  return (
    <>
      <div className="course-card">
        <div className="course-card-img">
          <img
            src={course?.course?.img || CardBg}
            alt="card"
          />
        </div>

        <div className="course-card-content">
          <p className="text-end fw-bold">
            {course?.course?.name ?? "Loading.."}
          </p>
          <div className="d-flex c-flex-row-reverse justify-content-between align-items-end">
            <div className="d-flex c-flex-row-reverse">
              <div>
                <div className="d-flex align-items-start c-flex-row-reverse justify-content-start  gap-2 mb-1">
                  <img src={Marker} className="card-label-icon" />
                  <p className="card-label text-end m-0">
                    {course?.location?.name}
                  </p>
                </div>

                <div className="d-flex align-items-center c-flex-row-reverse justify-content-start gap-2 my-3">
                  <img src={Calender} className="card-label-icon" />
                  <p className="card-label text-end m-0 " dir="rtl">
                    {course?.start_date
                      ? formatFullDate(course.start_date)
                      : "-"}
                    <br />
                    {course?.end_date ? formatFullDate(course.end_date) : "-"}
                  </p>
                </div>
                <div className="d-flex align-items-center c-flex-row-reverse gap-2 mb-1">
                  <img src={Clock} className="card-label-icon" />
                  <p className="card-label text-end m-0" dir="rtl">
                    {course?.start_time
                      ? formatInputTime(course.start_time)
                      : ""}{" "}
                    - {course?.end_time ? formatInputTime(course.end_time) : ""}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div className="free-card-btn">مجانية</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="course-card-divider"></div>
      <div>
        <Link to={`/course/${course?.course?.id}`}>
          <button className="register-card-btn">التفاصيل</button>{" "}
        </Link>
      </div>
    </>
  );
};

export default CourseCard;
