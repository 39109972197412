import React, { useState, useEffect } from "react";

import { PDFDocument, ReadingDirection, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import certificate from "../../assets/certificate/Certificate-NewDesign.pdf";
import customFontFile from "../../assets/certificate/Tajawal-Regular.ttf";

import createCertificate from "../../utils/certificate-generator";
import vendorCreateCertificate from "../../utils/vendor-certificate-generator";

import ListBtn from "../../assets/images/profilePage/list-btn.jpeg";
import { formatFullDate } from "../../utils/formatter";
import { toast } from "react-toastify";

const ProfileCourseMobileView = ({
  registration,
  getQuestionnaire,
  getScheduleID,
}) => {
  // const [modifiedPdfBytes, setModifiedPdfBytes] = useState(null);

  // const loadPdf = async () => {
  //   try {
  //     const pdfBytes = await fetch(certificate).then((res) =>
  //       res.arrayBuffer()
  //     );

  //     const pdfDoc = await PDFDocument.load(pdfBytes);
  //     pdfDoc.registerFontkit(fontkit);
  //     pdfDoc.setLanguage("ar-sy");

  //     const customFontBytes = await fetch(customFontFile).then((res) =>
  //       res.arrayBuffer()
  //     );
  //     const customFont = await pdfDoc.embedFont(customFontBytes);

  //     const [firstPage] = pdfDoc.getPages();

  //     const name = registration?.candidate?.ar_name;
  //     const course = registration?.course_name;

  //     const startDateEn = registration?.start_date;
  //     const endDateEn = registration?.end_date;

  //     const startDateAr = formatFullDate(registration?.start_date);
  //     const endDateAr = formatFullDate(registration?.end_date);

  //     const issueDateEn = "4 Jan 2024";
  //     const issueDateAr = "40 يناير 4202";
  //     const certificationNo = "11-22-22";

  //     const fontSize14 = 14;
  //     const fontSize11 = 11;
  //     const fontSize10 = 10;

  //     const { width, height } = firstPage.getSize();

  //     const nameWidth = customFont.widthOfTextAtSize(name, fontSize14);

  //     let nameFont = 14;
  //     let x1 = (width - nameWidth) / 2;
  //     let y1 = height - 212;

  //     const courseWidth = customFont.widthOfTextAtSize(course, fontSize14);
  //     let courseFont = 14;

  //     let x2 = (width - courseWidth) / 2;

  //     if (courseWidth > 500) {
  //       courseFont = 8;
  //       x2 = (width - courseWidth + 250) / 2;
  //     } else if (courseWidth > 400) {
  //       courseFont = 10;
  //       x2 = (width - courseWidth + 190) / 2;
  //     } else if (courseWidth > 300) {
  //       courseFont = 12;
  //       x2 = (width - courseWidth + 100) / 2;
  //     }

  //     const y2 = 247;

  //     // Start Date arabic
  //     const x5 = 390;
  //     const y5 = 204;

  //     // End Date arabic
  //     const x6 = 260;
  //     const y6 = 204;

  //     firstPage.drawText(name, {
  //       x: x1,
  //       y: y1,
  //       font: customFont,
  //       size: nameFont,
  //       color: rgb(0, 0, 0),
  //       // color: rgb(142, 70, 205),
  //     });
  //     firstPage.drawText(course, {
  //       x: x2,
  //       y: y2,
  //       font: customFont,
  //       size: courseFont,
  //       color: rgb(0, 0, 0),
  //     });

  //     firstPage.drawText(startDateEn, {
  //       x: x5,
  //       y: y5,
  //       font: customFont,
  //       size: fontSize11,
  //       color: rgb(0, 0, 0),
  //     });
  //     firstPage.drawText(endDateEn, {
  //       x: x6,
  //       y: y6,
  //       font: customFont,
  //       size: fontSize11,
  //       color: rgb(0, 0, 0),
  //     });

  //     const modifiedBytes = await pdfDoc.save();

  //     setModifiedPdfBytes(modifiedBytes);
  //   } catch (error) {
  //     console.error("Error loading PDF:", error);
  //   }
  // };

  // const downloadPdf = () => {
  //   // Trigger the download of the modified PDF
  //   if (modifiedPdfBytes) {
  //     const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "Certificate.pdf";
  //     a.click();
  //     URL.revokeObjectURL(url);
  //   }
  // };

  // useEffect(() => {

  //   if (registration?.course_name){

  //     loadPdf();
  //   }

  // }, [registration]);

  const [modifiedPdfBytes, setModifiedPdfBytes] = useState(null);

  const loadPdf = async () => {
    try {
      var modifiedBytes;
      if (registration.trainer == "ESM" || registration.trainer == "Event Academy" || registration.trainer == "Fhm" || registration.trainer == "Fennec"){
        modifiedBytes = await vendorCreateCertificate(registration);
        }
        else{
        modifiedBytes = await createCertificate(registration);

      }

      if (modifiedBytes) {
        setModifiedPdfBytes(modifiedBytes);
      }
    } catch (error) {
      console.error("Error loading PDF:", error);
    }
  };

  const downloadPdf = () => {
    if (modifiedPdfBytes) {
      toast.info("انتظر من فضلك");
      const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Certificate.pdf";
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    if (registration?.course_name) {
      loadPdf();
    }
  }, [registration]);

  return (
    <div className="profile-course">
      <div className="profile-course-header"></div>
      <div className="profile-course-content p-3">
        <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          <h5
            className="text-purple"
            style={{
              width: "fit-content",
              textAlign: "right",
            }}
          >
            اسم الدورة التدريبية
          </h5>

          <p
            className="text-green mb-0"
            style={{
              width: "fit-content",
              textAlign: "left",
            }}
            dir="rtl"
          >
            {registration?.course_name ?? "N/A"}
          </p>
        </div>
        <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          <h5 className="text-purple">تاريخ</h5>
          <p dir="rtl" className="text-green">
            {registration?.start_date
              ? formatFullDate(registration.start_date)
              : "N/A"}
            <br />
            {registration?.end_date
              ? formatFullDate(registration.end_date)
              : "N/A"}
          </p>
        </div>
        <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          <h5 className="text-purple"> التقييم المسبق</h5>

          {registration?.pre_assessment?.is_active &&
          !registration?.pre_assessment?.submitted ? (
            <img
              className="profil-list-btn"
              src={ListBtn}
              alt="btn"
              onClick={() =>
                getQuestionnaire({
                  questionnaireID: registration?.pre_assessment?.assessment,
                  registrationID: registration?.pre_assessment?.id,
                })
              }
            />
          ) : registration?.pre_assessment?.submitted ? (
            <p className={"mb-0 text-green"}>مُقَدَّم</p>
          ) : (
            <>
              <span className="text-green">التقييم غير متاح</span>
            </>
          )}
        </div>
        <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          <h5 className="text-purple">تقييم ما بعد</h5>

          {registration?.post_assessment?.is_active &&
          !registration?.post_assessment?.submitted ? (
            <img
              className="profil-list-btn"
              src={ListBtn}
              alt="btn"
              onClick={() =>
                getQuestionnaire({
                  questionnaireID: registration?.post_assessment?.assessment,
                  registrationID: registration?.post_assessment?.id,
                })
              }
            />
          ) : registration?.post_assessment?.submitted ? (
            <p className={"mb-0 text-green"}>مُقَدَّم</p>
          ) : (
            <>
              <span className="text-green">التقييم غير متاح</span>
            </>
          )}
        </div>
        <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          <h5 className="text-purple">شهادة </h5>
          {registration?.show_certificate ? (
            <p className="mb-0 download-certificate" onClick={downloadPdf}>
              تحميل الشهادة
            </p>
          ) : (
            "-"
          )}
          {/* {registration?.post_assessment?.is_pass ? (
            <p className="mb-0 download-certificate" onClick={downloadPdf}>
              تحميل الشهادة
            </p>
          ) : (
            <p className="mb-0 mx-4">-</p>
          )} */}
          {/* <p className="mb-0 mx-4">-</p> */}
        </div>
        <div className="d-flex flex-row-reverse justify-content-between align-items-center mb-3">
          <h5 className="text-purple">إعطاء ردود الفعل </h5>
          {registration?.feedback?.submitted ? (
            <p className="mb-0 text-green">مُقَدَّم</p>
          ) : registration?.feedback?.is_active &&
            !registration?.feedback?.submitted ? (
            <button
              className="table-register-btn"
              onClick={() => getScheduleID(registration?.schedule)}
            >
              تعليق
            </button>
          ) : (
            <p className="mb-0 mx-4">-</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileCourseMobileView;
