import React, { useEffect, useState } from "react";

import NavbarComponent from "../components/navbar.component";
import Footer from "../components/footer.component";

import { Link, useNavigate } from "react-router-dom";
import { Label, Input, Spinner } from "reactstrap";

import { candidateSchema } from "../validation-schema";

import { useFormik } from "formik";
import { IP } from "../api/ip";
import axios from "axios";
import { useMutation } from "react-query";
import { useStore } from "../api/useStore";
import { toast } from "react-toastify";
import { handleScrollToTop } from "../utils/helperFunction";

import {
  id_types,
  cities,
  employment_statuses,
  genders,
  nationalities,
  qualifications,
  residences,
  ages,
  whereYouHearFrom,
} from "../constants/signup-constants";

const SignUpPage = () => {
  document.title = "اشتراك | صناع السعادة";

  const navigate = useNavigate();

  const URL = `${IP}/api`;

  const sendSignUp = (payload) => {
    const sendData = { ...payload, age: parseInt(payload.age) };
    return axios.post(`${URL}/accounts/candidate-signup`, sendData);
  };

  const sendSignUpMutation = useMutation(sendSignUp, {
    onSuccess: (response) => {
      const tokens = response?.data?.tokens;

      if (tokens) {
        useStore.setState({ userStatus: true });
        useStore.setState({ accessToken: tokens.access });
        useStore.setState({ refreshToken: tokens.refresh });

        toast.success("مرحباً");
        navigate("/");
      } else {
        toast.error("هناك خطأ ما");
      }
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else if (
        response?.response?.status === 500 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = () => {
    sendSignUpMutation.mutate(values);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        ar_name: "",
        en_name: "",
        nationality: "",
        phone: "",
        email: "",
        password: "",
        id_type: "",
        id_no: "",
        qualification: "",
        employment_status: "",
        age: "",
        gender: "",
        residence: "",
        city: "",
        hearFrom: "",
        job_title: "",
        organization: "",
        years_of_experience: ""
      },
      validationSchema: candidateSchema,
      onSubmit,
      enableReinitialize: true,
    });

  return (
    <>
      <div className="auth-wrapper sign-up-wrapper">
        <NavbarComponent />

        <div className="form-container">
          <div className="form signup-form-container">
            <h2 className="text-purple mb-2">تعزيز مهاراتك لعالم اليوم</h2>

            <form className="text-end row" dir="rtl" onSubmit={handleSubmit}>
              <div className="col-12 col-md-6">
                <Label>الإسم الكامل باللغة العربية</Label>
                <Input
                  dir="rtl"
                  value={values.ar_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.ar_name && touched.ar_name
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="nameInput"
                  placeholder="أدخل الاسم باللغة العربية"
                  name="ar_name"
                />
                {errors.ar_name && touched.ar_name && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.ar_name}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6">
                <Label>الإسم الكامل باللغة الانجليزية</Label>
                <Input
                  dir="rtl"
                  value={values.en_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.en_name && touched.en_name
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="en_nameInput"
                  placeholder="أدخل الاسم باللغة الإنجليزية"
                  name="en_name"
                />
                {errors.en_name && touched.en_name && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.en_name}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6">
                <Label>الجنسية</Label>
                <Input
                  dir="rtl"
                  value={values.nationality}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.nationality && touched.nationality
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="nationalityInput"
                  placeholder="أدخل الجنسية"
                  name="nationality"
                >
                  <option value="">اختر الجنسية</option>
                  {nationalities.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                {errors.nationality && touched.nationality && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.nationality}
                  </div>
                )}
              </div>

              <div className="col-12 col-md-6">
                <Label>العمر</Label>
                <Input
                  dir="rtl"
                  value={values.age}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.age && touched.age
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="ageInput"
                  placeholder="أدخل العمر"
                  name="age"
                >
                  <option value="">اختر العمر</option>
                  {ages.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                {/* <Input
                  dir="rtl"
                  value={values.age}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                  className={
                    errors.age && touched.age
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="ageInput"
                  placeholder="أدخل العمر"
                  name="age"
                  min={"0"}
                /> */}
                {errors.age && touched.age && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.age}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6">
                <Label>رقم الجوال </Label>
                <Input
                  dir="rtl"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                  min={0}
                  className={
                    errors.phone && touched.phone
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="phoneInput"
                  placeholder="أدخل رقم الجوال"
                  name="phone"
                />
                {errors.phone && touched.phone && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.phone}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6">
                <Label>الجنس</Label>
                <Input
                  dir="rtl"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.gender && touched.gender
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="genderInput"
                  placeholder="أدخل الجنس"
                  name="gender"
                >
                  <option value="">اختر الجنس</option>
                  {genders.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                {errors.gender && touched.gender && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.gender}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6">
                <Label>البريد الإلكتروني</Label>
                <Input
                  dir="rtl"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="email"
                  className={
                    errors.email && touched.email
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="emailInput"
                  placeholder="أدخل البريد الإلكتروني"
                  name="email"
                />
                {errors.email && touched.email && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.email}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6">
                <Label>كلمة المرور</Label>
                <Input
                  dir="rtl"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  className={
                    errors.password && touched.password
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="passwordInput"
                  placeholder="أدخل كلمة المرور"
                  name="password"
                />
                {errors.password && touched.password && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.password}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6">
                {" "}
                <Label>نوع الوثيقة</Label>
                <Input
                  dir="rtl"
                  value={values.id_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.id_type && touched.id_type
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="id_typeInput"
                  name="id_type"
                >
                  <option value="">اختر نوع الهوية </option>
                  {id_types.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                {errors.id_type && touched.id_type && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.id_type}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6">
                <Label>رقم الهوية</Label>
                <Input
                  dir="rtl"
                  value={values.id_no}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                  className={
                    errors.id_no && touched.id_no
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="id_noInput"
                  placeholder="أدخل رقم الهوية"
                  name="id_no"
                />
                {errors.id_no && touched.id_no && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.id_no}
                  </div>
                )}
              </div>
           
              <div className="col-12 col-md-6">
                <Label>المؤهل العلمي</Label>
                <Input
                  dir="rtl"
                  value={values.qualification}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.qualification && touched.qualification
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="qualificationInput"
                  name="qualification"
                >
                  <option value="">اختر المؤهل</option>
                  {qualifications.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                {errors.qualification && touched.qualification && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.qualification}
                  </div>
                )}

              </div>
           
              <div className="col-12 col-md-6">
                <Label>الحالة الوظيفية</Label>
                <Input
                  dir="rtl"
                  value={values.employment_status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.employment_status && touched.employment_status
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="employment_statusInput"
                  name="employment_status"
                >
                  <option value="">اختر الحالة الوظيفية</option>
                  {employment_statuses.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                {errors.employment_status && touched.employment_status && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.employment_status}
                  </div>
                )}
              </div>
              
              <div className="col-12 col-md-6">
                <Label>مسمى وظيفي</Label>
                <Input
                  dir="rtl"
                  value={values.job_title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.job_title && touched.job_title
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="job_titleInput"
                  name="job_title"
                  placeholder="أدخل المسمى الوظيفي
                  "
                />
                {errors.job_title && touched.job_title && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.job_title}
                  </div>
                )}
              </div>
              
              <div className="col-12 col-md-6">
                <Label>جهة العمل</Label>
                <Input
                  dir="rtl"
                  value={values.organization}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.organization && touched.organization
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="organizationInput"
                  name="organization"
                  placeholder="أدخل الاسم الجهة العمل"
                />
                {errors.organization && touched.organization && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.organization}
                  </div>
                )}
              </div>
             
              <div className="col-12 col-md-6">
                <Label>عدد سنوات الخبرة</Label>
                <Input
                  dir="rtl"
                  value={values.years_of_experience}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.years_of_experience && touched.years_of_experience
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="years_of_experienceInput"
                  name="years_of_experience"
                  placeholder="أدخل عدد سنوات الخبرة"
                />
                {errors.years_of_experience && touched.years_of_experience && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.years_of_experience}
                  </div>
                )}
              </div>

              <div className="col-12 col-md-6">
                <Label>مكان الإقامة</Label>
                <Input
                  dir="rtl"
                  value={values.residence}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.residence && touched.residence
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="residenceInput"
                  name="residence"
                >
                  <option value="">اختر مكان الإقامة</option>
                  {residences.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                {errors.residence && touched.residence && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.residence}
                  </div>
                )}
              </div>

              <div className="col-12 col-md-6">
                <Label>المدينة</Label>
                <Input
                  dir="rtl"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.city && touched.city
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="cityInput"
                  name="city"
                >
                  <option value="">اختر المدينة</option>
                  {cities.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                {errors.city && touched.city && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.city}
                  </div>
                )}
              </div>

              <div className="col-12 col-md-6">
                <Label>كيف عرفت عن البرنامج</Label>
                <Input
                  dir="rtl"
                  value={values.hearFrom}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.hearFrom && touched.hearFrom
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  id="hearFromInput"
                  name="hearFrom"
                >
                  <option value="">يختار</option>
                  {whereYouHearFrom.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
                {errors.age && touched.age && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.age}
                  </div>
                )}
              </div>              

              <div className="text-center">
                <button
                  type="submit"
                  className="main-btn"
                  disabled={sendSignUpMutation.isLoading}
                >
                  {sendSignUpMutation.isLoading ? "يعالج" : "اشتراك"}
                  {sendSignUpMutation.isLoading && (
                    <Spinner size="sm" className="flex-shrink-0 ms-2" />
                  )}
                </button>
              </div>

              <div className="auth-bottom-text">
                <p>
                  هل لديك حساب بالفعل؟
                  <Link className="me-1" to="/sign-in">
                    تسجيل الدخول
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer scroll={handleScrollToTop} />
    </>
  );
};

export default SignUpPage;
