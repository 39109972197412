import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import useAxios from "../../api/useAxios";
import FeedbackSimpleQuestion from "./feedback-simple-question.component";
import FeedbackMCQQuestion from "./feedback-mcq-question.component";
import { toast } from "react-toastify";

const ModalFeedback = ({
  feedbackModal,
  toggleFeedbackModal,
  scheduleID,
  refetchRegistrations
  // fetchFeedback,
}) => {
  // const [simpleArray, setSimpleArray] = useState([]);

  const api = useAxios();
  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(
    `feedback-form-${scheduleID}`,
    async () => api.get(`/feedback/feedback-form/${scheduleID}`),
    {
      cacheTime: 0,
      enabled: scheduleID ? true : false,
    }
  );

  const feedbackData = response?.data ?? {};

  let simpleArray = [];

  const handleSimpleAnswer = (question, answer) => {
    // Check if the qId already exists in simpleArray
    const existingIndex = simpleArray.findIndex(
      (item) => item.question === question
    );

    if (existingIndex !== -1) {
      // If qId exists, replace the existing object with {qId, ans}
      simpleArray[existingIndex] = { question, answer };
    } else {
      // If qId doesn't exist, push {qId, ans} into simpleArray
      simpleArray.push({ question, answer });
    }

  };

  let mcqArray = [];

  const handleMCQOption = (mcq, choice) => {
    const existingIndex = mcqArray.findIndex((item) => item.mcq === mcq);

    if (existingIndex !== -1) {
      // If qId exists, replace the existing object with {qId, ans}
      mcqArray[existingIndex] = { mcq, choice };
    } else {
      // If qId doesn't exist, push {qId, ans} into simpleArray
      mcqArray.push({ mcq, choice });
    }
  };

  function isAnyAnswerEmpty() {
    for (let i = 0; i < simpleArray?.length; i++) {
      if (simpleArray[i].answer == "") {
        return true; // Found an empty answer key
      }
    }
    return false; // No empty answer keys found
  }

  const submit = (payload) => {
    return api.post(`/feedback/submit-feedback`, {
      feedbackform: feedbackData?.id,
      simple_answer_responses: simpleArray,
      multiple_choice_responses: mcqArray,
    });
  };

  const submitMutation = useMutation(submit, {
    onSuccess: (response) => {
      toast.success("مُقَدَّم");
      refetchRegistrations()
      toggleFeedbackModal();
    },
    onError: (data) => {
      if (data?.response?.status === 400 && !!data?.response?.data?.message) {
        toast.error(data.response.data.message);
      } else if (
        data?.response?.status === 500 &&
        !!data?.response?.data?.message
      ) {
        toast.error(data.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const checkSubmit = () => {
    const isEmpty = isAnyAnswerEmpty();
    if (mcqArray.length == feedbackData?.mcq_questions?.length && !isEmpty) {
      submitMutation.mutate();
    } else {
      toast.error("يرجى محاولة جميع الأسئلة");
    }
  };

  return (
    <Modal
      isOpen={feedbackModal}
      toggle={toggleFeedbackModal}
      className="modal-question"
      size="lg"
    >
      <ModalBody className="p-0">
        <div className="modal-question-header">
          <h3 className="text-white text-center m-0">إعطاء ردود الفعل</h3>
        </div>
        <div className="modal-main">
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center py-5">
              <div class="spinner-border text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {feedbackData?.simple_questions?.map((quest, index) => {
                return (
                  <FeedbackSimpleQuestion
                    data={quest}
                    idx={index}
                    answer={handleSimpleAnswer}
                  />
                );
              })}
              {feedbackData?.mcq_questions?.map((quest, index) => {
                return (
                  <FeedbackMCQQuestion
                    data={quest}
                    idx={index}
                    simpleLength={feedbackData?.simple_questions?.length}
                    answer={handleMCQOption}
                  />
                );
              })}
            </>
          )}
          <div className=" d-flex gap-4">
            <Button className="question-success-btn" onClick={checkSubmit}>

              يُقدِّم

            </Button>
            <Button
              onClick={toggleFeedbackModal}
              className="question-close-btn"
            >
              يغلق
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalFeedback;
