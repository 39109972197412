import React, { useEffect, useState, useRef } from "react";
import NavbarComponent from "../components/navbar.component";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import "../assets/sass/course-detail.scss";
import { Col, Container, Row } from "reactstrap";
import Footer from "../components/footer.component";

import { useQuery } from "react-query";
import axios from "axios";

import { IP } from "../api/ip";
import { formatFullDate } from "../utils/formatter";

import SearchCourses from "../components/home/search-courses.component";
import ProfileTable from "../components/profile/profileTable.component";

import ScheduleTable from "../components/scheduleTable.component";
import { handleScrollToTop } from "../utils/helperFunction";
import EnrollmentSuccessModal from "../components/enrollment-success.modal";

import { slideInDown, fadeIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

const SchedulePage = () => {
  document.title = " جدول | صناع السعادة";
  const [searchData, setSearchData] = useState(null);
  const [searchLoading, setSearchLoading] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleSearchResults = (results) => {
    setSearchData(results);
  };

  
  const openModal = () => {
    setScrollPosition(window.scrollY); // Save current scroll position
    setShowModal(true);
  };

  // Function to close the modal and reset the scroll position
  const closeModal = () => {
    setShowModal(false);
  };
  
  useEffect(() => {
    
    if (!showModal){
      window.scrollTo({top: scrollPosition, left:0, behavior: 'instant'}); 
    }
    
  }, [showModal])

  return (
    <>
      {showModal ? (
        <div>
          <StyleRoot>
            <EnrollmentSuccessModal back={closeModal} />
          </StyleRoot>
        </div>
      ) : (
        <>
          <div className="course-detail-wrapper">
            <NavbarComponent activePage={"schedule"} />
            <div className="course-detail-header d-flex align-items-center justify-content-end px-5">
              <h1>جدول</h1>
            </div>

            <SearchCourses
              onSearchResults={handleSearchResults}
              searchLoading={(load) => setSearchLoading(load)}
            />
          </div>

          <div className="container schedule-table-wrapper">
            <ScheduleTable
              data={searchData}
              searchLoading={searchLoading}
              showResponse={openModal}
            />
          </div>

          <Footer scroll={handleScrollToTop} />
        </>
      )}
    </>
  );
};

export default SchedulePage;
